import React, { useEffect, useState } from 'react'
import Navbar from '../navbar'
import FooterSection from '../footer'
import Wishlist from './wishlist'
import { wishList } from '../../ApiConfigs/ApiConfig'
import axios from 'axios'




function MyWishListIndex() {

    const [wishlistData, setWishlistData] = useState()

    const wishlist = async () => {
        try {
            const response = await axios.get(`${wishList}`)
            setWishlistData(response?.data?.result)
        } catch (error) {

        }
    }
    useEffect(() => {
        wishlist()
    }, [])
    return (
        <>
            <Navbar wishlistProps={wishlistData} />
            <Wishlist wishlistGet={wishlist} />
            <FooterSection />
        </>
    )
}

export default MyWishListIndex

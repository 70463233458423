import React from 'react'
import './index.css'
import { useNavigate } from 'react-router-dom';
import { formatDate } from '.';

export const getDeliveryStatus = (status) => {
    switch (status) {
        case 'process':
            return (
                <div className='disc-container-1 disc-con-clr-1 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-1'></div>
                    <p className='p-0 m-0'>In Progress</p>
                </div>
            );
        case 'shipped':
            return (
                <div className='disc-container-1 disc-con-clr-2 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-2'></div>
                    <p className='p-0 m-0'>Shipped</p>
                </div>
            );
        case 'delivered':
            return (
                <div className='disc-container-1 disc-con-clr-2 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-2'></div>
                    <p className='p-0 m-0'>Delivered</p>
                </div>
            );
        case 'cancelled':
            return (
                <div className='disc-container-1 disc-con-clr-3 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-3'></div>
                    <p className='p-0 m-0'>Cancelled</p>
                </div>
            );
        default:
            return (
                <div className='disc-container-1 disc-con-clr-1 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-1'></div>
                    <p className='p-0 m-0'>In Progress</p>
                </div>
            );
    }
};

function MyOrderCard({ data }) {

    const navigate = useNavigate()

    return (
        <div onClick={() => navigate(`/myOrder/view/${data?._id}`)} className='my-order-card p-3 mb-3'>
            <div className='d-flex '>
                {data?.customizeImages?.[0] ? (
                    <img
                        alt={`product image`}
                        src={data?.customizeImages?.[0]}
                        className='my-order-img'
                    />
                ) : (
                    <a href={data?.file?.[0]} target='_blank'>
                        <img
                            alt={`product image`}
                            src={data?.file?.[0].endsWith('.pdf') ? 'https://aimmgr.s3.ap-south-1.amazonaws.com/mgr/public/socialpost/file-blvvhhfusm11y4jw4.png' : data?.file?.[0]}
                            className='my-order-img'
                        />
                    </a>
                )}
                <div className='ms-3'>
                    <h1 className='h5'>{data?.product?.name}</h1>
                    <p style={{ color: '#858585' }} className='p-0 m-0 mb-2'>Quantity : {data?.quantity}</p>
                    <h1 className='h6'>Order placed on : {formatDate(data?.date)}</h1>
                    <h1 className='h5 text-success p-0 m-0'>₹{parseInt(data?.total)}</h1>
                </div>
            </div>
            <div className='d-flex flex-column justify-content-between align-items-end'>
                {getDeliveryStatus(data?.status)}
                {data?.expectedDeliveryDate && (
                    <h1 className='h6 m-0 p-0'>Delivery expected by {formatDate(data?.expectedDeliveryDate)}</h1>
                )}
            </div>
        </div>
    )
}

export default MyOrderCard

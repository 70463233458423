import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { Button, FormFeedback, Form, Input, Label, Row, Col, Spinner, Card, Collapse, CardBody, CardHeader } from 'reactstrap';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowLeft, MinusCircle, PlusCircle, Trash2, X } from 'react-feather';
import { product, productCategory, productApi, productFAQ } from '../../../../ApiConfigs/ApiConfig';
import { multiImageUpload, uploadImage } from '../../upload/index';
import { fieldTypeApiNew } from '../../../../ApiConfigs/ApiConfig';
import Navbar from '../../../navbar';
import FooterSection from '../../../footer';
import { useNavigate, useParams } from 'react-router-dom';
import { RiImageAddFill } from 'react-icons/ri';
import { TagsInput } from 'react-tag-input-component';

function EditProductIndex({ faq }) {
    const navigate = useNavigate()
    const { id } = useParams()
    const [getEditData, setGetEditData] = useState()
    const [getData, setGetData] = useState([])
    const [fieldTypeData, setFieldTypeData] = useState([])
    const inputRef = useRef(null);
    const [galleryImages, setGalleryImages] = useState([]);
    const imageRef = useRef(null);
    const imageRef1 = useRef(null);
    const imageRefs = useRef([]);
    const [imageUpload, setImageUpload] = useState([])
    const [image, setImage] = useState(null);
    const [icon, setIcon] = useState(null);
    const [loads, setloads] = useState(false)
    const [loader, setLoader] = useState(false)

    const [loaders, setloaders] = useState(false)
    const [loading, setLoading] = useState(false)
    // const [profileImages, setProfileImages] = useState([]);
    const [profileImageUrl, setProfileImageUrl] = useState('');
    const [profileIconUrl, setProfileIconUrl] = useState('');
    const [profileImages, setProfileImages] = useState([]);
    const [customImages, setCustomImages] = useState([]);


    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const [selectedCustomImageIndex, setSelectedCustomImageIndex] = useState(null);
    const inputRef1 = useRef(null);
    const inputRef2 = useRef(null);
    const [metaKeywords, setMetaKeywords] = useState([]);
    const [customizeImageSize, setCustomizeImageSize] = useState([]);

    const handleMetaKeywordsChange = (keywords) => setMetaKeywords(keywords);
    const handleSizeChange = (ImageSize) => setCustomizeImageSize(ImageSize);


    const formSchema = yup.object().shape({
        // productName: yup.mixed().required('Please Enter Your productCategory '),
        name: yup.string().required('Please Enter Your productName').matches(/^[a-zA-Z0-9\s]+$/, 'Product Name should not contain special characters'),
        fullDescription: yup.string().required('Please Enter Your fullDescription'),
        metaTitle: yup.string().required('Please Enter Your Meta Title').matches(/^[a-zA-Z0-9\s]+$/, 'Meta Title should not contain special characters'),
        metaDescription: yup.string().required('Please Enter Your Meta Description'),
        metaKeywords: yup.array().of(yup.string().required('Keyword is required'))
        // shortDescription: yup.string().required('Please Enter Your shortDescription'),
    })

    const {
        reset,
        control,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({ mode: 'onChange', resolver: yupResolver(formSchema) });


    const submitForm = async (data) => {
        delete data?.img_url
        delete data?.galleryImages

        // const customImage = {
        //     customizeImages: customImages?.map((el, i) => ({
        //         name: data?.customizeImages[i]?.name,
        //         image: customImages[i]?.image || '', 
        //     }))
        // }

        const customImage = {
            customizeImages: colorProducts.map(colorProduct => ({
                colourCode: colorProduct.colourCode,
                images: colorProduct.images.map(imageData => ({
                    name: imageData.name,
                    image: imageData.image
                }))
            }))
        };

        const payload = {
            ...data,
            galleryImages: profileImages,
            customizeImages: customImage.customizeImages,
            image: profileImageUrl,
            icon: profileIconUrl,
            metaKeywords: metaKeywords,
            customizeImageSize: customizeImageSize
        };

        try {
            const response = await axios.put(`${productApi}/${getEditData._id}`, payload)
            navigate('/admin/product')
            toast.success(response?.data?.msg)
        } catch (error) {
            toast.error(error?.response?.data?.msg?.message || error?.response?.data?.msg)
        }

    }

    const removeImage = () => {
        setImage(null);
    };
    const handleImageUpload = async (e) => {
        if (e.target.files && e.target.files[0]) {
            setImage(URL.createObjectURL(e.target.files[0]));
        }
        if (e.target.files) {
            try {
                setLoader(true)
                const formData = new FormData();
                formData.append('file', e.target.files[0]);

                const uploadData = await uploadImage(formData);
                if (uploadData && uploadData.result && uploadData.result.length) {
                    setProfileImageUrl(uploadData.result[0].location);

                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            } finally {
                setLoader(false)
            }
        }
    };

    const handleIconUpload = async (e) => {
        if (e.target.files && e.target.files[0]) {
            setIcon(URL.createObjectURL(e.target.files[0]));
        }
        if (e.target.files) {
            try {
                setloads(true)
                const formData = new FormData();
                formData.append('file', e.target.files[0]);

                const uploadData = await uploadImage(formData);
                if (uploadData && uploadData.result && uploadData.result.length) {
                    setProfileIconUrl(uploadData.result[0].location);

                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            } finally {
                setloads(false)
            }
        }
    };




    const handleRemoveImage = (index) => {
        const updatedImages = [...profileImages];
        updatedImages.splice(index, 1);
        setProfileImages(updatedImages);
        setSelectedImageIndex(null);
    };

    const handleRemoveCustomImage = (index) => {
        const updatedImages = [...customImages];
        updatedImages.splice(index, 1);
        setCustomImages(updatedImages);
        setSelectedCustomImageIndex(null);
    };

    const handleProfileUpload = async (e) => {
        if (e.target.files) {
            try {
                setLoading(true);
                const formData = new FormData();

                for (let i = 0; i < e.target.files.length; i++) {
                    formData.append('files', e.target.files[i]);
                }

                const uploadData = await multiImageUpload(formData);

                if (uploadData && uploadData.result && uploadData.result.length) {
                    const uploadedImages = uploadData.result.map(file => file.location);

                    setProfileImages(prevImages => [...prevImages, ...uploadedImages]);
                }
            } catch (error) {
                toast.error('Something went wrong...');
            } finally {
                setLoading(false);
            }
        }
    };

    const handleCustomImageUpload = async (e, colorIndex, imageIndex) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const localImageUrl = URL.createObjectURL(file); // Create a local URL for the image

            try {
                setloaders(true);
                const formData = new FormData();
                formData.append('file', file);

                // Perform the image upload to your server
                const uploadData = await uploadImage(formData);

                if (uploadData && uploadData.result && uploadData.result.length) {
                    const uploadedImageUrl = uploadData.result[0].location;

                    // Set both the local image URL and the uploaded image URL
                    const updatedColorProducts = [...colorProducts];
                    updatedColorProducts[colorIndex].images[imageIndex] = {
                        name: updatedColorProducts[colorIndex].images[imageIndex]?.name || '',
                        image: uploadedImageUrl,
                        localImageUrl, // Use localImageUrl to preview before upload completes
                    };

                    setColorProducts(updatedColorProducts);
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            } finally {
                setloaders(false);
            }
        }
    };


    const getProduct = async () => {
        try {
            const response = await axios.get(productCategory)
            setGetData(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    }

    const getFieldType = async (id) => {
        try {
            const response = await axios.get(fieldTypeApiNew)
            setFieldTypeData(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    }

    const editData = async () => {
        try {
            const response = await axios.get(`${product}/${id}`)
            setGetEditData(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    }

    useEffect(() => {
        getProduct()
        editData()
        getFieldType()
    }, [])

    useEffect(() => {
        if (getEditData) {
            reset({
                name: getEditData?.name,
                fullDescription: getEditData?.fullDescription,
                shortDescription: getEditData?.shortDescription,
                product_url: getEditData?.product_url,
                productOverview: getEditData?.productOverview?.map(item => ({ description: item.description })) || [],
                description: getEditData?.description?.map(item => ({ description: item.description })) || [],
                metaTitle: getEditData?.metaTitle,
                metaDescription: getEditData?.metaDescription,
            });
            // Check if galleryImages is not undefined before setting profileImages
            setProfileImages(getEditData?.galleryImages || []);
            setCustomImages(getEditData?.customizeImages || []);
            setImageUpload(getEditData?.image);
            setProfileImageUrl(getEditData?.image);
            setProfileIconUrl(getEditData?.icon);
            setImage(getEditData?.image);
            setIcon(getEditData?.icon);
            setMetaKeywords(getEditData?.metaKeywords);
            setCustomizeImageSize(getEditData?.customizeImageSize);

            // getEditData?.customizeImages?.map((item, i) => (
            //     setValue(`customizeImages[${i}].name`, item.name)
            // ))
            const formattedColorProducts = getEditData?.customizeImages?.map(item => ({
                colourCode: item.colourCode || '',
                images: item.images?.map(image => ({
                    name: image.name || '',
                    image: image.image || ''
                })) || []
            })) || [];

            setColorProducts(formattedColorProducts);
        }
    }, [getEditData, reset, setValue, setProfileImages, setCustomImages, setImageUpload, setProfileImageUrl, setProfileIconUrl, setImage, setIcon, setMetaKeywords, setCustomizeImageSize]);

    const [colorProducts, setColorProducts] = useState([
        {
            colourCode: '',
            images: [{ name: '', image: '' }]
        }
    ]);



    return (
        <>
            <Navbar />
            <div className='pt-5 pb-5' style={{ paddingLeft: '8%', paddingRight: '8%', }}>
                <div className='d-flex' style={{ cursor: 'pointer' }} onClick={() => navigate('/admin/product')}><ArrowLeft /><p>Back ProductPage</p> </div>
                <h1 className='text-center pb-5'>Edit Product</h1>
                <Form onSubmit={handleSubmit(submitForm)}>

                    <Row >
                        <Col sm={4} md={6}>
                            <div className='mb-2'>
                                <Label>Product Name</Label>
                                <Controller
                                    name='name'
                                    id='name'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (<Input type="text" {...field} invalid={errors.name && true} placeholder="Enter Name" />)} />
                                {errors.name && <FormFeedback>{errors.name.message}</FormFeedback>}
                            </div>
                        </Col>

                        <Col sm={6} md={6}>
                            <div className='mb-2'>
                                <Label>Full Description</Label>
                                <Controller
                                    name='fullDescription'
                                    id='fullDescription'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (<Input type="text" rows={5} cols={60} {...field} invalid={errors.fullDescription && true} placeholder="Enter fullDescription" />)} />
                                {errors.fullDescription && <FormFeedback>{errors.fullDescription.message}</FormFeedback>}
                            </div>
                        </Col>
                        <Col sm={12} md={12} lg={6}>
                            <div className='mb-3 '>
                                <Label style={{ fontWeight: "bolder" }}>Meta Title</Label>
                                <Controller
                                    name='metaTitle'
                                    id='metaTitle'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (<Input type="text" {...field} invalid={errors.metaTitle && true} placeholder="Enter your Meta Title" />)} />
                                {errors.metaTitle && <FormFeedback>{errors.metaTitle.message}</FormFeedback>}
                            </div>
                        </Col>
                        <Col sm={12} md={12} lg={6}>
                            <div className='mb-3 '>
                                <Label style={{ fontWeight: "bolder" }}>Meta Description</Label>
                                <Controller
                                    name='metaDescription'
                                    id='metaDescription'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (<Input type="textarea" {...field} invalid={errors.metaDescription && true} placeholder="Enter your Meta Descritpion" />)} />
                                {errors.metaDescription && <FormFeedback>{errors.metaDescription.message}</FormFeedback>}
                            </div>
                        </Col>
                        <Col sm={12} md={12} lg={6}>
                            <div className="col-span-12 md:col-span-12 lg:col-span-6 intro-y">
                                <Label>Meta Keywords</Label>
                                <div className="mt-2">
                                    <TagsInput
                                        placeHolder="Enter Company Meta Keywords"
                                        onChange={handleMetaKeywordsChange}
                                        value={metaKeywords || []}
                                    />
                                    <span>Press enter to add Meta Keywords</span>
                                    {errors.metaKeywords && (
                                        <div className="mt-2 text-danger">
                                            {errors.metaKeywords.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} md={12} lg={6}>
                            <div className="col-span-12 md:col-span-12 lg:col-span-6 intro-y">
                                <Label>Size</Label>
                                <div className="mt-2">
                                    <TagsInput
                                        placeHolder="Enter Product size"
                                        onChange={handleSizeChange}
                                        value={customizeImageSize}
                                    />
                                    <span>Press enter the size of the product</span>
                                    {errors.customizeImageSize && (
                                        <div className="mt-2 text-danger">
                                            {errors.customizeImageSize.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                        {/* <Col sm={6} md={6}>
                            <div className='mb-2'>
                                <Label>Short Description</Label>
                                <Controller
                                    name='shortDescription'
                                    id='shortDescription'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (<Input type="text" rows={5} cols={60} {...field} invalid={errors.shortDescription && true} placeholder="Enter shortDescription" />)} />
                                {errors.shortDescription && <FormFeedback>{errors.shortDescription.message}</FormFeedback>}
                            </div>
                        </Col> */}
                        <Col sm={6} md={6}>
                            <div className='mb-2'>
                                <Label>Product URL</Label>
                                <Controller
                                    name='product_url'
                                    id='product_url'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (<Input type="text" rows={5} cols={60} {...field} invalid={errors.product_url && true} placeholder="Enter Product URL" />)} />
                                {errors.product_url && <FormFeedback>{errors.product_url.message}</FormFeedback>}
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                            <div className='mb-1 mt-2'>
                                <Label>Description</Label>
                                <Controller
                                    name='description'
                                    control={control}
                                    defaultValue={[]}
                                    render={({ field }) => (
                                        <div>
                                            {field.value.map((item, index) => (
                                                <div key={index} className='mb-2 d-flex'>
                                                    <Input
                                                        type='text'
                                                        name={`description[${index}].description`}
                                                        value={field.value[index]?.description || ''}
                                                        invalid={errors.description && errors.description[index] && errors.description[index].description && true}
                                                        placeholder={`Enter description ${index + 1}`}
                                                        onChange={(e) => {
                                                            const updatedValues = [...field.value];
                                                            updatedValues[index] = { description: e.target.value };
                                                            field.onChange(updatedValues);
                                                        }}
                                                    />
                                                    {errors.description && errors.description[index] && errors.description[index].description && (
                                                        <FormFeedback>{errors.description[index].description.message}</FormFeedback>
                                                    )}
                                                    <div
                                                        className='ms-2 mt-2 text-danger'
                                                        size='sm'
                                                        onClick={() => {
                                                            const updatedValues = [...field.value];
                                                            updatedValues.splice(index, 1);
                                                            field.onChange(updatedValues);
                                                        }}
                                                    >
                                                        <Trash2 />
                                                    </div>
                                                </div>
                                            ))}
                                            <Button
                                                className='mt-3 overview-btn'
                                                color='primary'
                                                size='sm'
                                                onClick={() => {
                                                    field.onChange([...field.value, { description: '' }]);
                                                }}
                                            >
                                                <PlusCircle /> Description
                                            </Button>
                                        </div>
                                    )}
                                />
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                            <div className='mb-1 mt-2'>
                                <Label>Product Overview</Label>
                                <Controller
                                    name='productOverview'
                                    control={control}
                                    defaultValue={[]}
                                    render={({ field }) => (
                                        <div>
                                            {field.value.map((item, index) => (
                                                <div key={index} className='mb-2 d-flex'>
                                                    <Input
                                                        type='text'
                                                        name={`productOverview[${index}].description`}
                                                        value={field.value[index]?.description || ''}
                                                        invalid={errors.productOverview && errors.productOverview[index] && errors.productOverview[index].description && true}
                                                        placeholder={`Enter description ${index + 1}`}
                                                        onChange={(e) => {
                                                            const updatedValues = [...field.value];
                                                            updatedValues[index] = { description: e.target.value };
                                                            field.onChange(updatedValues);
                                                        }}
                                                    />
                                                    {errors.productOverview && errors.productOverview[index] && errors.productOverview[index].description && (
                                                        <FormFeedback>{errors.productOverview[index].description.message}</FormFeedback>
                                                    )}
                                                    <div
                                                        className='ms-2 mt-2 text-danger'
                                                        size='sm'
                                                        onClick={() => {
                                                            const updatedValues = [...field.value];
                                                            updatedValues.splice(index, 1);
                                                            field.onChange(updatedValues);
                                                        }}
                                                    >
                                                        <Trash2 />
                                                    </div>
                                                </div>
                                            ))}
                                            <Button
                                                className='mt-3 overview-btn'
                                                color='primary'
                                                size='sm'
                                                onClick={() => {
                                                    field.onChange([...field.value, { description: '' }]);
                                                }}
                                            >
                                                <PlusCircle /> Description
                                            </Button>
                                        </div>
                                    )}
                                />
                            </div>
                        </Col>
                        <Col sm={4} md={6}>
                            <div className="mb-3">
                                <Label>
                                    <div className='d-flex'>
                                        <div>Product Image</div>
                                    </div>
                                </Label>

                                <Controller
                                    name='image'
                                    id='image'
                                    control={control}
                                    defaultValue=''
                                    render={({ field }) => (
                                        <div className="d-flex" style={{ cursor: 'pointer' }}>
                                            <input
                                                style={{ display: 'none' }}
                                                id="image"
                                                type="file"
                                                {...field}
                                                accept=".jpeg,.png,.jpg"
                                                invalid={errors.image && true}
                                                ref={imageRef}
                                                onChange={handleImageUpload}
                                            />

                                            {loader ? (
                                                <Spinner color='primary' />
                                            ) : (
                                                <Card style={{ width: "250px", height: "160px", justifyContent: "center", alignItems: "center" }} onClick={() => imageRef?.current?.click()}>
                                                    {image ? (
                                                        <>
                                                            <img
                                                                alt="uploaded image"

                                                                src={image}
                                                                style={{ width: '140px', objectFit: 'cover' }}
                                                            />
                                                            <div
                                                                className='image-remove'
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '5px',
                                                                    right: '1px',
                                                                    cursor: 'pointer',
                                                                    color: 'red',
                                                                }}
                                                                onClick={() => setImage(null)} >
                                                                <X />
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div className='text-primary text-center'>
                                                            <h1 style={{ color: "primary" }}><RiImageAddFill /></h1>
                                                            <p>*Upload your Cover Images</p>
                                                            {/* <p style={{ marginTop: '-15px' }}>(Preferably Vector Image)</p> */}
                                                        </div>
                                                    )}
                                                </Card>
                                            )}

                                            {errors.image && <FormFeedback>{errors.image.message}</FormFeedback>}
                                        </div>
                                    )}
                                />
                            </div>

                        </Col>
                        <Col sm={4} md={6}>
                            <div className="mb-3">
                                <Label>
                                    <div className='d-flex'>
                                        <div>Product Icon</div>
                                    </div>
                                </Label>

                                <Controller
                                    name='icon'
                                    id='icon'
                                    control={control}
                                    defaultValue=''
                                    render={({ field }) => (
                                        <div className="d-flex" style={{ cursor: 'pointer' }}>
                                            <input
                                                style={{ display: 'none' }}
                                                id="icon"
                                                type="file"
                                                {...field}
                                                accept=".jpeg,.png,.jpg"
                                                invalid={errors.icon && true}
                                                ref={imageRef1}
                                                onChange={handleIconUpload}
                                            />

                                            {loads ? (
                                                <Spinner color='primary' />
                                            ) : (
                                                <Card style={{ width: "250px", height: "160px", justifyContent: "center", alignItems: "center" }} onClick={() => imageRef1?.current?.click()}>
                                                    {icon ? (
                                                        <>
                                                            <img
                                                                alt="uploaded image"

                                                                src={icon}
                                                                style={{ width: '140px', objectFit: 'cover' }}
                                                            />
                                                            <div
                                                                className='image-remove'
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '5px',
                                                                    right: '1px',
                                                                    cursor: 'pointer',
                                                                    color: 'red',
                                                                }}
                                                                onClick={() => setIcon(null)} >
                                                                <X />
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div className='text-primary text-center'>
                                                            <h1 style={{ color: "primary" }}><RiImageAddFill /></h1>
                                                            <p>*Upload your Product icon</p>
                                                            {/* <p style={{ marginTop: '-15px' }}>(Preferably Vector Image)</p> */}
                                                        </div>
                                                    )}
                                                </Card>
                                            )}

                                            {errors.icon && <FormFeedback>{errors.icon.message}</FormFeedback>}
                                        </div>
                                    )}
                                />
                            </div>

                        </Col>

                        <Col sm={4} md={6} >
                            <div className='d-flex'>
                                <div> <b> Product Gallery Images</b></div>
                            </div>
                            <Card className='p-3 mt-1 mt-md-3' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "400px" }}>
                                <div className="">
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {profileImages?.map((image, index) => (
                                            <div key={index} className="m-2">
                                                <div className='d-flex'>
                                                    <img
                                                        alt={`preview image ${index + 1}`}
                                                        src={image}
                                                        style={{
                                                            width: '100px',
                                                            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => setSelectedImageIndex(index)}
                                                    />
                                                    <div className="cursor-pointer image-close" style={{ cursor: 'pointer' }} onClick={() => handleRemoveImage(index)}>
                                                        <X />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="d-flex justify-content-center" style={{ cursor: 'pointer' }}>
                                        <input style={{ display: 'none' }} type="file" ref={inputRef1} accept=".jpeg,.png,.jpg" onChange={handleProfileUpload} multiple />
                                        {loading ? (
                                            <Spinner />
                                        ) : (
                                            <div className='text-center text-primary' onClick={() => inputRef1?.current?.click()}>

                                                {profileImages.length === 0 ? (
                                                    <>
                                                        <h1> <RiImageAddFill /></h1>
                                                        <p>*Upload your Gallery Images</p>
                                                        <p style={{ marginTop: '-15px' }}>(Preferably Vector Image)</p>
                                                    </>
                                                ) : (
                                                    <>
                                                        <h1> <RiImageAddFill /></h1>
                                                        <p>*Add more images</p>
                                                        <p style={{ marginTop: '-15px' }}>(Preferably Vector Image)</p>

                                                    </>
                                                )}

                                            </div>
                                        )}
                                    </div>

                                </div>
                            </Card>

                        </Col>
                        {/* <Col sm={12} lg={6} md={6}>
                            <div className='mb-1 d-flex flex-column'>
                                <Label style={{ marginBottom: '5px', paddingRight: "10px", fontWeight: "bolder" }}>
                                    Product Custom Images :
                                </Label>
                            </div>
                            <Card className='p-3 mt-1 mt-md-3 mb-3' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div className="">
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {customImages?.map((image, index) => (
                                            <div key={index} className="m-2" style={{ position: 'relative' }}>
                                                <img
                                                    alt={`preview image ${index + 1}`}
                                                    src={image}
                                                    style={{
                                                        width: '100px',
                                                        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => setSelectedCustomImageIndex(index)}
                                                />
                                                <div className="cursor-pointer image-close" style={{ position: 'absolute', top: '1px', right: '1px', cursor: 'pointer' }} onClick={() => handleRemoveCustomImage(index)}>
                                                    <X style={{ width: '16px', height: '16px' }} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="d-flex justify-content-center" style={{ cursor: 'pointer' }}>
                                        <input style={{ display: 'none' }} type="file" ref={inputRef2} accept=".jpeg,.png,.jpg,.jfif" onChange={handleCustomImageUpload} multiple />
                                        {load ? (
                                            <Spinner />
                                        ) : (
                                            <div className='text-center text-primary' onClick={() => inputRef2?.current?.click()}>
                                                {customImages.length === 0 ? (
                                                    <>
                                                        <h1> <RiImageAddFill /></h1>
                                                        <p>*Upload your Custom Images</p>
                                                        <p style={{ marginTop: '-15px' }}>(Preferably Vector Image)</p>
                                                    </>
                                                ) : (
                                                    <>
                                                        <h1> <RiImageAddFill /></h1>
                                                        <p>*Add more images</p>
                                                        <p style={{ marginTop: '-15px' }}>(Preferably Vector Image)</p>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Card>
                        </Col> */}
                        {/* <Col sm={12} md={6} lg={6}>
                            <div className='d-flex'>
                                <div> <b>Custom Images :</b></div>
                            </div>
                            {customImages?.map((imageData, index) => (
                                <div key={imageData._id}>
                                    <Row className='mt-2 d-flex justify-content-center'>
                                        <Col sm={5} md={5} lg={5}>
                                            <div className='mb-3'>
                                                <Label>Name</Label>
                                                <Controller
                                                    name={`customizeImages[${index}].name`}
                                                    id='name'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Input
                                                            {...field}
                                                            id='name'
                                                            type='text'
                                                            placeholder='Enter Custom Image Name'
                                                        />
                                                    )} />
                                            </div>
                                        </Col>
                                        <Col sm={5} md={5} lg={3}>
                                            <div className='mb-3'>
                                                <Label style={{ fontWeight: "bolder" }}>Image</Label>
                                                <div className="d-flex" style={{ cursor: 'pointer' }}>
                                                    <input
                                                        name={`customizeImages[${index}].image`}
                                                        id='image'
                                                        style={{ display: 'none' }}
                                                        type="file"
                                                        accept=".jpeg,.png,.jpg,.jfif"
                                                        ref={el => imageRefs.current[index] = el}  // Store refs for each input
                                                        onChange={(e) => handleCustomImageUpload(e, index)}
                                                    />
                                                    <Card style={{ width: "100px", height: "100px", justifyContent: "center", alignItems: "center" }} onClick={() => imageRefs.current[index]?.click()}>
                                                        {imageData.image ? (
                                                            <>
                                                                <img
                                                                    alt="uploaded image"
                                                                    src={imageData?.image}
                                                                    style={{ width: '60px', objectFit: 'cover' }}
                                                                />
                                                                <div
                                                                    className='image-remove'
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: '5px',
                                                                        right: '1px',
                                                                        cursor: 'pointer',
                                                                        color: 'red',
                                                                    }}
                                                                    onClick={() => {
                                                                        const updatedImages = [...customImages];
                                                                        updatedImages[index].image = '';
                                                                        setCustomImages(updatedImages);
                                                                    }}
                                                                >
                                                                    <X />
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <div className='text-primary d-flex align-items-center w-75 h-75'>
                                                                <div className='text-center'>
                                                                    <h1 style={{ color: "primary" }}><RiImageAddFill size={20} /></h1>
                                                                    <p style={{ fontSize: '12px' }}>*Upload your Custom Image</p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Card>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={2} md={2} lg={2}>
                                            <div className='mt-0 mb-5 pt-0 mt-lg-4 mb-lg-3 pt-lg-3' style={{ cursor: 'pointer', color: "#AF1B1B" }} onClick={() => {
                                                const updatedImages = customImages.filter((_, i) => i !== index);
                                                setCustomImages(updatedImages);
                                            }}>
                                                <Trash2 />
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr />
                                </div>
                            ))}
                            <div className='d-flex'>
                                <Button type="button" className='overview-btn' onClick={() => setCustomImages([...customImages, { name: '', image: '' }])}>
                                    <PlusCircle /> Add Custom Image
                                </Button>
                            </div>
                        </Col> */}
                        <Col sm={12} md={6} lg={6}>
                            <div className='d-flex'>
                                <div> <b>Custom Images :</b></div>
                            </div>

                            {colorProducts.map((colorProduct, colorIndex) => (
                                <div key={colorIndex} className='mt-3'>
                                    <Row className='mb-3'>
                                        <Col sm={12} md={6} lg={6}>
                                            <Label>Color Code</Label>
                                            <Input
                                                type="text"
                                                value={colorProduct.colourCode}
                                                onChange={(e) => {
                                                    const updatedColorProducts = [...colorProducts];
                                                    updatedColorProducts[colorIndex].colourCode = e.target.value;
                                                    setColorProducts(updatedColorProducts);
                                                }}
                                                placeholder='Enter Color Code'
                                            />
                                        </Col>
                                        <Col sm={2} lg={2} md={2}>
                                            <div
                                                className='mt-0 mb-5 pt-0 mt-lg-3 mb-lg-3 pt-lg-3'
                                                style={{ cursor: 'pointer', color: "#AF1B1B" }}
                                                onClick={() => {
                                                    const updatedColorProducts = colorProducts.filter((_, i) => i !== colorIndex);
                                                    setColorProducts(updatedColorProducts);
                                                }}
                                            >
                                                <Trash2 />
                                            </div>
                                        </Col>
                                    </Row>
                                    {colorProduct.images.map((imageData, imageIndex) => (
                                        <div key={imageIndex} className='mt-3'>
                                            <Row className='d-flex justify-content-center'>
                                                <Col sm={5} md={5} lg={5}>
                                                    <div className='mb-3'>
                                                        <Label>Name</Label>
                                                        <Input
                                                            type='text'
                                                            value={imageData.name}
                                                            placeholder='Enter Custom Image Name'
                                                            onChange={(e) => {
                                                                const updatedColorProducts = [...colorProducts];
                                                                updatedColorProducts[colorIndex].images[imageIndex].name = e.target.value;
                                                                setColorProducts(updatedColorProducts);
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col sm={3} md={3} lg={3}>
                                                    <div className='mb-3'>
                                                        <Label style={{ fontWeight: "bolder" }}>Image</Label>
                                                        <div className="d-flex" style={{ cursor: 'pointer' }}>
                                                            <input
                                                                style={{ display: 'none' }}
                                                                type="file"
                                                                accept=".jpeg,.png,.jpg,.jfif"
                                                                ref={el => (imageRefs.current[colorIndex * 100 + imageIndex] = el)}
                                                                onChange={(e) => handleCustomImageUpload(e, colorIndex, imageIndex)}
                                                            />
                                                            <Card
                                                                style={{ width: "100px", height: "100px", justifyContent: "center", alignItems: "center" }}
                                                                onClick={() => imageRefs.current[colorIndex * 100 + imageIndex]?.click()}
                                                            >
                                                                {imageData.image ? (
                                                                    <>
                                                                        <img
                                                                            alt="uploaded image"
                                                                            src={imageData.image}
                                                                            style={{ width: '60px', objectFit: 'cover' }}
                                                                        />
                                                                        <div
                                                                            className='image-remove'
                                                                            style={{
                                                                                position: 'absolute',
                                                                                top: '5px',
                                                                                right: '1px',
                                                                                cursor: 'pointer',
                                                                                color: 'red',
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation(); // Prevent triggering the file input click
                                                                                const updatedColorProducts = [...colorProducts];
                                                                                updatedColorProducts[colorIndex].images[imageIndex].image = '';
                                                                                setColorProducts(updatedColorProducts);
                                                                            }}
                                                                        >
                                                                            <X />
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <div className='text-primary d-flex align-items-center w-75 h-75'>
                                                                        <div className='text-center'>
                                                                            <h1 style={{ color: "primary" }}><RiImageAddFill size={20} /></h1>
                                                                            <p style={{ fontSize: '12px' }}>*Upload your Custom Image</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Card>
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col sm={2} md={2} lg={2}>
                                                    <div
                                                        className='mt-0 mb-5 pt-0 mt-lg-4 mb-lg-3 pt-lg-3'
                                                        style={{ cursor: 'pointer', color: "#AF1B1B" }}
                                                        onClick={() => {
                                                            const updatedColorProducts = [...colorProducts];
                                                            updatedColorProducts[colorIndex].images = updatedColorProducts[colorIndex].images.filter((_, i) => i !== imageIndex);
                                                            setColorProducts(updatedColorProducts);
                                                        }}
                                                    >
                                                        <Trash2 />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <hr />
                                        </div>
                                    ))}

                                    <div className='d-flex'>
                                        <Button
                                            type="button"
                                            className='overview-btn'
                                            onClick={() => {
                                                const updatedColorProducts = [...colorProducts];
                                                updatedColorProducts[colorIndex].images.push({ name: '', image: '' });
                                                setColorProducts(updatedColorProducts);
                                            }}
                                        >
                                            <PlusCircle /> Add Custom Image
                                        </Button>
                                    </div>
                                    <hr />
                                </div>
                            ))}

                            <div className='d-flex mt-3'>
                                <Button
                                    type="button"
                                    className='overview-btn'
                                    onClick={() => setColorProducts([...colorProducts, { colourCode: '', images: [{ name: '', image: '' }] }])}
                                >
                                    <PlusCircle /> Add Color Product
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <div className='d-flex justify-content-end'>
                        <Button className='overview-btn' type='submit'>Submit</Button>
                    </div>


                </Form >

            </div >
            {/* <FooterSection /> */}
        </>
    )
}

export default EditProductIndex
import React, { useEffect, useState } from "react";
import Navbar from "../navbar";
import FooterSection from "../footer";
import { Link, useNavigate } from "react-router-dom";
import { speedSale } from "../../ApiConfigs/ApiConfig";
import axios from "axios";
import { Badge, Card, Col, Row, Spinner } from "reactstrap";
import star from "../../assets/images/logo/Star 2.png"
import star5 from "../../assets/images/logo/Star 5.png"
import { FaAngleRight } from "react-icons/fa6";

function PopularProducts() {
  const [loading, setLoading] = useState(false);
  const [dataa, setData] = useState([]);

  const navigate = useNavigate();

  const additionalData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${speedSale}`);
      setData(response?.data?.result);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    additionalData();
  }, []);

  const navToHome = () => {
    navigate("/");
  };
  const navToProduct = () => {
    navigate("/products");
  };

  const navToPage = (id) => {
    navigate(`/products/viewProduct/${id}`);
  };

  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const truncateDescription = (description, maxLength) => {
    return description.length > maxLength
      ? `${description.slice(0, maxLength)}...`
      : description;
  };

  const navToViewPage = (product_url) => {
    navigate(`/products/viewProduct/${product_url}`);
  };

  return (
    <>
      <Navbar />
      {loading ? (
        <div
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner color="primary">Loading...</Spinner>
        </div>
      ) : (
        <div
          className="pt-3 des1"
        >
          <p className='pt-3' style={{ cursor: 'pointer', color: '#333333', opacity: '0.8' }}><span onClick={navToHome}>Home</span> / <span style={{ color: '#E4510B' }} onClick={navToProduct}>Popular products</span></p>
          <div style={{ width: '100%' }} className='d-flex align-items-center mt-5'>
            <h1 className='product-overall-title'>Popular Products</h1>
            <hr className='ms-3 call2' style={{ height: '2px', width: '100%', background: 'black' }} />
          </div>
          <Row className="mt-5">
            {dataa?.map((eachItem, i) => (
              <Col sm={12} md={6} lg={4} xl={3} key={i}>
                <Card className="mt-3" onClick={() => navToViewPage(eachItem?.product_url)}
                  style={{ border: '1px solid #F0F0F0', borderRadius: '15px', cursor: 'pointer' }}
                >
                  <div>
                    <div style={{ padding: '5%' }}>
                      <div style={{ width: '100%' }} className='d-flex'>
                        <div className='position-relative w-100'>
                          <img
                            src={eachItem?.image}
                            style={{
                              borderRadius: "4px",
                              height: '250px',
                              width: "100%"
                            }}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="d-block mt-3">
                        <div className='mt-2'>
                          <div className="d-flex justify-content-between">
                            <div>
                              <h6
                                style={{
                                  fontSize: "18px",
                                  cursor: "pointer",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  color: '#2B2B2D'
                                }}
                              >
                                {eachItem?.name?.substring(0, 15)}{" "}
                                {eachItem?.name?.length > 15 ? "..." : ""}
                              </h6>
                            </div>
                            <div style={{ marginTop: '-3px' }}>
                              <img src={star} alt="star1" />
                              <img className='ms-1' src={star} alt="star2" />
                              <img className='ms-1' src={star} alt="star3" />
                              <img className='ms-1' src={star} alt="star4" />
                              <img className='ms-1' src={star5} alt="star5" />
                            </div>
                          </div>
                          <p
                            style={{
                              fontSize: "16px",
                              cursor: "pointer",
                              lineHeight: '24px',
                              color: '#858585',
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {eachItem?.productCategory?.name?.substring(0, 30)}{" "}
                            {eachItem?.productCategory?.name?.length > 30 ? "..." : ""}
                          </p>
                        </div>
                        <div className="mt-3">
                          <p
                            className="m-0"
                            style={{
                              fontSize: "14px",
                              color: '#37A003',
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "100%",
                            }}
                          >
                            As Low As
                          </p>
                          <div className='d-flex justify-content-between'>
                            <div className="d-flex flex-column ">
                              <h2
                                className=""
                                style={{
                                  fontSize: `${eachItem?.mimimumAmount &&
                                    eachItem.mimimumAmount.toString().length > 1
                                    ? eachItem.mimimumAmount.toString().length > 4
                                      ? "18px"
                                      : "24px"
                                    : "24px"
                                    }`,
                                  textAlign: `${eachItem?.mimimumAmount &&
                                    eachItem.mimimumAmount.toString().length === 1
                                    ? "start"
                                    : "start"
                                    }`,
                                  color: '#37A003',
                                }}
                              >
                                {/* ₹{eachItem?.mimimumAmount * eachItem.100} */}

                                {eachItem?.offer === true ? ((eachItem?.offerAmount * eachItem?.minimunQuantity || 0).toLocaleString('en-IN', {
                                  maximumFractionDigits: 0,
                                  style: 'currency',
                                  currency: 'INR',
                                })) : ((eachItem?.mimimumAmount * eachItem?.minimunQuantity || 0).toLocaleString('en-IN', {
                                  maximumFractionDigits: 0,
                                  style: 'currency',
                                  currency: 'INR',
                                }))}

                              </h2>
                              {/* {eachItem?.offer === true ? (<span style={{ fontSize: "16px", display: 'flex', marginTop: '2px' }} className='ms-2 old-price'>{(amount * 100).toLocaleString('en-IN', {
                              maximumFractionDigits: 0,
                              style: 'currency',
                              currency: 'INR',
                            })}</span>) : (null)} */}

                              <span style={{ color: '#858585', fontSize: "16px", display: 'flex', marginTop: '0px' }} className=''>For {eachItem?.minimunQuantity} Pieces</span>
                            </div>
                            <div className="d-flex align-items-center">
                              <Link
                                to={`/products/viewProduct/${eachItem?.product_url}`}
                                className="" style={{ textDecoration: 'none' }}
                              >
                                <button style={{ minWidth: '100px' }} className='ms-4 show-now' onClick={() => navToViewPage(eachItem?.product_url)}>Shop Now <FaAngleRight size={15} /></button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      )}
      <FooterSection />
    </>
  );
}

export default PopularProducts;

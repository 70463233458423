import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { speedSale } from '../../../ApiConfigs/ApiConfig';
import toast from 'react-hot-toast';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card, Col } from 'reactstrap';
import { MapPin, Star } from 'react-feather';
import man from '../../../assets/images/banner/man.jpg'
import man1 from '../../../assets/images/banner/man1.jpg'
import man2 from '../../../assets/images/banner/man2.jpg'
import man3 from '../../../assets/images/banner/man3.jpg'
import man4 from '../../../assets/images/banner/man4.jpg'
import man5 from '../../../assets/images/banner/serious-man-looking-camera_23-2147799044.avif'
import categoryLeft from "../../../assets/images/banner/Category-left.png";
import categoryRight from "../../../assets/images/banner/Category-right.png";
import review1 from "../../../assets/images/banner/review1.png";
import star from "../../../assets/images/logo/Star 2.png"
import star5 from "../../../assets/images/logo/Star 5.png"
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

import './index.css'

function TestimonialIndex() {

    const [loading, setLoading] = useState(false);
    const [dataa, setData] = useState();


    const additionalData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(speedSale)
            setData(response?.data?.result)

        } catch (error) {
            toast.error(error?.response?.data?.msg)
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        additionalData()
    }, [])


    const cardDetails = [
        {
            id: "1",
            img: man4,
            name: "Palanikumar",
            date: "02 April 2024",
            description: "I needed new business cards in a hurry for a networking event, and Printon delivered with speed and precision. The online design tool was intuitive, and I had no trouble creating a professional-looking card."
        },
        {
            id: "2",
            img: man,
            name: "Riyazkhan",
            date: "24 May 2020",
            description: "I run a small online business and depend on eye-catching stickers and labels for packaging. The printing website has become my go-to for these essential branding elements. The stickers are of high quality and smooth finish.",
        },
        {
            id: "1",
            img: man2,
            name: "Logeshwaran",
            date: "04 April 2022",
            description: "I've been using this Printon for diaries, and I'm impressed with the durability and attention to detail. The paper quality is excellent. I appreciate the consistency in quality and will continue to be a loyal customer. ",
        },
        {
            id: "2",
            img: man3,
            name: "Rajesh",
            date: "02 May 2021",
            description: "I recently ordered custom calendars for a family reunion, and the Printon exceeded my expectations. The design process was user-friendly, allowing me to personalize each month with memorable photos.",
        },
        {
            id: "1",
            img: man1,
            name: "Arun",
            date: "30 July 2021",
            description: "I'm extremely satisfied with the quality, service, and variety offered by this Printon. Their commitment to excellence is evident in every product they deliver. Highly recommended for all your printing needs!",
        },
        {
            id: "2",
            img: man5,
            name: "Jabeer",
            date: "15 August 2020",
            description: "The giving the booklets a professional look and feel. The entire process, from designing to delivery, was seamless. I highly recommend their booklet printing services for anyone looking to make a strong impact at their events.",
        },
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        centerMode: false,
        autoplay: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                },
            },
        ],

    };
    const sliderRef = useRef(null);

    const nextSlide = () => {
        sliderRef.current.slickNext();
    };

    const prevSlide = () => {
        sliderRef.current.slickPrev();
    };

    return (
        <>

            <Col lg={12} md={12} sm={12}>
                <div className='d-flex justify-content-center align-items-center'>
                    <div className='mt-5 d-flex'>
                        <div>
                            <img className='img1' src={categoryLeft} alt="" />
                        </div>
                        <div className=' ms-2 me-2 mt-2 d-flex justify-content-center align-items-center'>
                            <h3 className='pp' style={{ fontWeight: '400', fontSize: '40px', font: 'Manrope' }}>Reviews</h3>
                        </div>
                        <div>
                            <img className='img1' src={categoryRight} alt="" />
                        </div>
                    </div>
                </div>
            </Col>
            <div className='pb-5' style={{ paddingLeft: '5%', paddingRight: '5%' }}>


                <div className='mt-5 position-relative'>
                    <Slider {...settings} ref={sliderRef}>
                        {cardDetails?.map((eachDetail) => (
                            <div className='pt-5 pb-5'>
                                <Card key={eachDetail.id} className='testmonialsCard  mt-1 mb-5'>
                                    <div className='d-flex justify-content-between'>
                                        <div className='d-block'>
                                            <img src={eachDetail.img} className='testimonialImage img-fluid' alt='image' />
                                            <div className=''>
                                                <h5 className='pt-3 ms-2'><b> {eachDetail.name}</b></h5>
                                                <p className=' testimonialLocation ms-2  d-flex'><p className='ms-1'>{eachDetail.date}</p></p>
                                            </div>

                                        </div>
                                        <div className='testimonialRatings' style={{ marginTop: '-3px' }}>
                                            <img className='testimonialRatings1' src={star} alt="star1" />
                                            <img className='ms-1 testimonialRatings1' src={star} alt="star2" />
                                            <img className='ms-1 testimonialRatings1' src={star} alt="star3" />
                                            <img className='ms-1 testimonialRatings1' src={star} alt="star4" />
                                            <img className='ms-1 testimonialRatings1' src={star5} alt="star5" />
                                        </div>
                                        {/* <p className=' testimonialRatings pt-4'>  <Star style={{ color: '#e4510b' }} /> {eachDetail.ratings}</p> */}
                                    </div>
                                    <div>
                                        <img style={{ width: '100%' }} src={review1} alt="" />
                                    </div>

                                    <p className=' testimonialDescription text-center'> {eachDetail.description} </p>
                                </Card>
                            </div>
                        ))}
                    </Slider>
                    <div className="custom-nav-buttons mt-5 mb-5 d-flex justify-content-end me-5">
                        <div onClick={prevSlide}>
                            <button style={{ background: '#FBD7C6', color: '#E4510B', border: 'none', borderRadius: '50%', width: '50px', height: '50px' }} className="prev-button"><FiArrowLeft className='d-flex align-items-center justify-content-center' size={35} /></button>
                        </div>
                        <div className='ms-4' onClick={nextSlide}>
                            <button style={{ background: '#FBD7C6', color: '#E4510B', border: 'none', borderRadius: '50%', width: '50px', height: '50px' }} className="next-button"><FiArrowRight className='d-flex align-items-center justify-content-center' size={35} /></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TestimonialIndex

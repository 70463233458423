import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Form, FormFeedback, Input, InputGroup, InputGroupText, Label } from 'reactstrap'
import * as yup from 'yup';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { yupResolver } from '@hookform/resolvers/yup';
import { checkEmail, checkMobile, otpSend, signUp } from '../../../ApiConfigs/ApiConfig';
import toast from 'react-hot-toast';
import axios from 'axios';
import SignUpOtp from './mobileOTP/SignUpOtp';

function SignUp({ toggleAuth, toggle1 }) {

    const [isUser, setIsUser] = useState(false);

    const SignupSchema = yup.object().shape({
        name: yup.string().required('Please Enter Your Name').matches(/^[A-Za-z\s]+$/, 'Name must contain only letters'),
        // email: yup.string().email('Invalid email format').required('Enter Your Email'),
        email: yup
            .string()
            .email('Please Enter a Vaild Email')  // Checks for general email format
            .matches(
                /^[^\s@]+@(gmail\.com|hotmail\.com|yahoo\.com|[^\s@]+\.(co\.in|in))$/,
                'Please Enter a Vaild Email'
            )
            .required('Enter Your Email'),

        mobile: yup
            .string()
            .matches(/^[6-9]\d{9}$/, 'Please Enter a Valid Mobile Number')  // Starts with 6-9 and has 10 digits
            .required('Enter Your Mobile Number'),
        password: yup.string()
            .min(8, 'Password must be at least 8 characters')
            .matches(/[a-z]/, 'Must contain at least one lowercase letter')
            .matches(/[A-Z]/, 'Must contain at least one uppercase letter')
            .matches(/\d/, 'Must contain at least one number')
            .required('Enter Your Password'),
        confirmPassword: yup.string()
            .oneOf([yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Your Password'),
    });

    const {
        handleSubmit,
        control,
        watch,
        formState: { errors }
    } = useForm({ mode: 'onChange', resolver: yupResolver(SignupSchema) });
    const password = watch('password');
    const confirmPassword = watch('confirmPassword');
    const mobile = watch('mobile');
    const email = watch('email');
    const [userData, setUserData] = useState();
    const [userMail, setUserMail] = useState();
    const [cartKey, setCartKey] = useState('');
    const [showOTP, setShowOTP] = useState(false);
    const toggleOTP = () => setShowOTP(!showOTP);

    const handlesignup = async (data) => {

        const payload = {
            name: data.name,
            email: data.email,
            mobile: data.mobile,
            password: data.password,
            // user_id: userData?.result?._id
        }
        console.log(payload)
        try {
            const responseSignUp = await axios.post(signUp, payload);
            toast.success(responseSignUp?.data?.msg);
            localStorage.setItem('signUpEmail', data.email)
            localStorage.setItem('signUpMobile', data.mobile)
            localStorage.setItem('signUpPassword', data.password)
            setShowOTP(true);
        } catch (error) {
            toast.error(error.response.data.msg)
            setIsUser(true)
        }
    };

    // const SendOTP = async () => {
    //     const payload = {
    //         mobile: mobile,
    //     };

    //     try {
    //         const response = await axios.post(otpSend, payload);
    //         toast.success(response?.data?.msg);
    //         localStorage.setItem('SignInMobile', mobile);
    //         setCartKey('yes');
    //         setShowOTP(true);
    //         // toggleAuth()
    //     } catch (error) {
    //         toast.error(error?.response?.data?.msg);
    //     }
    // };



    useEffect(() => {
        const isValidEmail = (email) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        };

        if (email && isValidEmail(email)) {
            const payload = {
                email: email
            }
            const checkEmail1 = async () => {
                try {
                    const response = await axios.post(checkEmail, payload);
                    setUserMail(response.data); // Store response data
                } catch (error) {
                    console.error('API Error:', error);
                }
            };

            checkEmail1(); // Call the API when the email is valid
        }
    }, [email]);

    useEffect(() => {
        if (mobile && mobile.length === 10) {
            const payload = {
                mobile: mobile
            }
            const checkMobile1 = async () => {
                try {
                    const response = await axios.post(checkMobile, payload);
                    setUserData(response?.data);
                    console.log(userData)
                } catch (error) {
                    console.error('API Error:', error);
                }
            };

            checkMobile1(); // Call the API when the mobile number is 10 digits
        }
    }, [mobile]);

    const handleFormSubmit = (event) => {
        event.preventDefault();
        handleSubmit(handlesignup)();
    };

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <>
            {showOTP === false ? (
                <div >
                    <div className=" text-center">
                        {/* <img src={logo} alt="logo" height={60} className="me-2" /> */}
                        <h1 className="auth-head mt-2">Sign up</h1>

                    </div>
                    <Form onSubmit={handleFormSubmit}>
                        <div className='p-3'>
                            <div className='mb-2'>
                                <Label className='signup'> Your Name </Label>
                                <Controller
                                    name='name'
                                    id='name'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Input
                                            type="text"
                                            {...field}
                                            pattern="^[A-Za-z\s]+$"  // Only allows alphabets and spaces
                                            invalid={errors.name && true}
                                            placeholder="Enter Your Name"
                                        />
                                    )}
                                />
                                {errors.name && <FormFeedback>{errors.name.message}</FormFeedback>}
                            </div>
                            <div className='mb-2'>
                                <Label className='signup'>Mobile Number </Label>
                                <div className='d-flex'>
                                    <input className='me-2' style={{ width: '50px' }} type="text" readOnly value={'+91'} />
                                    <Controller
                                        name='mobile'
                                        id='mobile'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input type="number" {...field} invalid={errors.mobile && true} placeholder="Enter Your Mobile Number"
                                            style={{ '-webkit-appearance': 'none', '-moz-appearance': 'textfield' }} />)} />
                                </div>
                                {errors.mobile && <FormFeedback>{errors.mobile.message}</FormFeedback>}
                                {mobile && mobile.length === 10 && userData?.msg === "Mobile already exist " && (
                                    <div style={{ color: 'red', marginTop: '-10px' }}>
                                        {userData.msg}*
                                    </div>
                                )}
                            </div>
                            <div className='mb-2'>
                                <Label className='signup'>Email ID</Label>
                                <Controller
                                    name='email'
                                    id='email'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (<Input type="email" {...field} invalid={errors.email && true} placeholder="Enter Your Email Id" />)} />
                                {email && userMail?.msg === "Email already exists." && (
                                    <div style={{ color: 'red', marginTop: '-10px' }}>
                                        {userMail.msg}*
                                    </div>
                                )}
                                {errors.email && <FormFeedback>{errors.email.message}</FormFeedback>}
                            </div>
                            <div className='mb-2'>
                                <Label className='signup'>Password </Label>
                                <div className={`input-tick ${password && confirmPassword && password === confirmPassword ? 'valid' : ''}`}>
                                    <InputGroup>
                                        <Controller
                                            name='password'
                                            id='password'
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <Input
                                                    type={showPassword ? "text" : "password"}
                                                    {...field}
                                                    invalid={errors.password && true}
                                                    placeholder="Enter Your Password"
                                                    className='m-0 p-0 ps-2'
                                                />
                                            )}
                                        />
                                        <InputGroupText onClick={togglePasswordVisibility} style={{ cursor: 'pointer', height: '40px' }}>
                                            {showPassword ? <FaEyeSlash style={{ color: '#E45D25' }} /> : <FaEye style={{ color: '#E45D25' }} />}
                                        </InputGroupText>
                                    </InputGroup>
                                </div>
                                <p style={{ fontSize: '11px', marginTop: '5px' }}>*Password must be at least 8 characters, one lowercase letter, one Uppercase letter, at least one number.</p>
                                {errors.password && <FormFeedback>{errors.password.message}</FormFeedback>}
                            </div>
                            <div className='mb-2'>
                                <Label className='signup'>Confirm Password</Label>
                                <div className={`input-tick ${password && confirmPassword && password === confirmPassword ? 'valid' : ''}`}>
                                    <InputGroup>
                                        <Controller
                                            name='confirmPassword'
                                            id='confirmPassword'
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <Input
                                                    type={showConfirmPassword ? "text" : "password"}
                                                    {...field}
                                                    invalid={errors.confirmPassword && true}
                                                    placeholder="Enter Your Confirm Password"
                                                />
                                            )}
                                        />
                                        <InputGroupText onClick={toggleConfirmPasswordVisibility} style={{ cursor: 'pointer', height: '40px' }}>
                                            {showConfirmPassword ? <FaEyeSlash style={{ color: '#E45D25' }} /> : <FaEye style={{ color: '#E45D25' }} />}
                                        </InputGroupText>
                                    </InputGroup>
                                </div>
                                {errors.confirmPassword && <FormFeedback>{errors.confirmPassword.message}</FormFeedback>}
                            </div>
                            <div>
                                <p style={{ fontSize: '14px', fontWeight: '400', color: '#333333D1' }}>To verify your number, we will send you a text message with a temporary code.</p>
                            </div>
                            <div className=''>
                                <button className='auth-button1' type='submit' >
                                    Continue
                                </button>
                            </div>
                            <div style={{ width: '100%' }} className='d-flex mt-2'>
                                <hr style={{ width: '100%' }} />
                                <p className='ms-1 me-1' style={{ color: '#333333D1', minWidth: 'fit-content', fontSize: '16px', fontWeight: '400' }}>Already have an account?</p>
                                <hr style={{ width: '100%' }} />
                            </div>
                            <div className=''>
                                <button style={{ height: '45px', width: '100%', border: '1px solid #C6C6C6', borderRadius: '5px', fontSize: '20px', fontWeight: '600', background: 'transparent' }} onClick={toggleAuth}>
                                    Login
                                </button>
                            </div>
                        </div>
                    </Form >
                </div >
            ) : (
                <SignUpOtp toggleAuth={toggleAuth} toggle1={toggle1} toggleOTP={toggleOTP} setCartKey={'yes'} showOTP={setShowOTP} />
            )}



            {/* <div>
                {showOTP &&
                    <SignUpOtp toggleAuth={toggleAuth} toggle1={toggle} toggleOTP={toggleOTP} setCartKey={'yes'} showOTP={setShowOTP} />
                }
            </div> */}

        </>
    )
}

export default SignUp

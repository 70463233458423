import React from 'react'
import { Form, Modal, ModalBody, ModalHeader } from 'reactstrap';
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";

const Preview = ({ modal, toggle, cardImages }) => {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const image = cardImages?.[0]


    return (
        <div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Preview</ModalHeader>
                <Form>
                    <ModalBody>
                        {cardImages?.length > 1 ? (
                            <Carousel responsive={responsive}
                                autoPlay={true}
                                swipeable={true}
                                draggable={true}
                                showDots={true}
                                arrows={true}
                                autoPlaySpeed={2000}
                                infinite={true}>
                                {cardImages.map((image, index) => (
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <a href={image} target='_blank'>
                                            <img
                                                className='mb-3'
                                                style={{ width: '300px', height: "300px" }}
                                                alt=""
                                                src={image.endsWith('.pdf') ? 'https://aimmgr.s3.ap-south-1.amazonaws.com/mgr/public/socialpost/file-blvvhhfusm11y4jw4.png' : image}
                                            />
                                        </a>
                                    </div>
                                ))}
                            </Carousel>
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <a href={cardImages?.[0]} target='_blank'>
                                    <img
                                        className='mb-3'
                                        style={{ width: '300px', height: "300px" }}
                                        alt=""
                                        src={image?.endsWith('.pdf') ? 'https://aimmgr.s3.ap-south-1.amazonaws.com/mgr/public/socialpost/file-blvvhhfusm11y4jw4.png' : cardImages?.[0]}
                                    />
                                </a>
                            </div>)}

                    </ModalBody>
                </Form>
            </Modal>
        </div>
    )
}

export default Preview
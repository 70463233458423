import React, { useEffect, useState, useRef } from 'react'
import './index.scss'
import { Link, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import { AlignCenter, ArrowRight, BookOpen, Box, ChevronDown, ChevronUp, File, FileText, Heart, LogOut, Package, Percent, ShoppingBag, Phone, ShoppingCart, TrendingUp, User, Search, Home, ExternalLink, AlertCircle } from 'react-feather'
import printOnLogo from '../../assets/images/logo/printon logo2.webp'
import logo from "../../assets/images/logo/logo.png"
import search from "../../assets/images/logo/search.svg"
import { Button, Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import AuthModal from './auth/authModal'
import OtpVerification from './otp'
import { cart, menuProduct, menuProductAd, productSearch, wishList } from '../../ApiConfigs/ApiConfig'
import axios from 'axios'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import NewAuthModal from './newAuth'
import ProductModal from './productModal'
import FinalAuthModal from './finalAuth'
import MobileOTPModal from './finalAuth/mobileOTP'
import officialLogo from '../../../src/assets/images/logo/printon logo-01.png'
import search23 from '../../assets/images/banner/search.png';
import HomeMenu from './homeMenu/HomeMenu'
import { Newspaper } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'
import { GoSearch } from "react-icons/go";
import { RiArrowDropDownLine } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import ChooseProductModal from '../home/landingPage/ChooseProductModal'


function Navbar({ closeSearchModal, wishlistProps, cartData }) {

    const [isProductsDropdownOpen, setProductsDropdownOpen] = useState(false);
    const queryClient = useQueryClient();
    const [modal, setModal] = useState(false);
    const [isToggle, setToggle] = useState(false)
    const [wishlistData, setWishlistData] = useState([]);
    const [commonData, setCommonData] = useState([]);
    const [productSearchData, setProductSearch] = useState([]);
    const [showSearchResults, setShowSearchResults] = useState(false);
    const [isModalOpen, setModalOpen] = useState(null);
    const [logoutValue, setLogoutValue] = useState(false)
    const [isActiveWishlist, setActiveWhislist] = useState(false)
    const [isActiveCart, setActiveCart] = useState(false)
    const [isStartPrinting, setIsStartPrinting] = useState(false)
    const searchContainerRef = useRef(null);

    const toggle = () => setModal(!modal);

    const [modalOtp, setModalOtp] = useState(false);

    const toggleOtp = () => setModalOtp(!modal);

    const nagivate = useNavigate()
    const toggleModal = () => setModalOpen(!isModalOpen);

    const [isShown, setIsShown] = useState(false);

    const toggleProductsDropdown = () => {
        setProductsDropdownOpen(!isProductsDropdownOpen);
    };

    const closeProductsDropdown = () => {
        setProductsDropdownOpen(false);
    };


    const [menuActive, setMenuActive] = useState(false);

    const toggleMenu = () => {
        setMenuActive(!menuActive);
    };

    const toggleEmail = () => {
        setToggle(!isToggle)
    }

    const logout = () => {
        setLogoutValue(true)
        // Clearing all local storage
        localStorage.clear();
        navigate('/');
        window.location.reload();
    }

    const role = localStorage.getItem('role')
    const name = localStorage.getItem('name')
    const mobile = localStorage.getItem('mobile')

    const navigate = useNavigate()

    const isLogin = localStorage.getItem('role')

    const [dataa, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const numberOfItem = dataa?.products?.length;

    const additionalData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${cart}`)
            setData(response?.data?.result)

        } catch (error) {

        } finally {
            setLoading(false);
        }
    }
    const numberOfData = wishlistData?.length;


    const handleSearchInputChange = (event) => {
        setProductSearch(event.target.value);
    };
    const handleSearchSubmit = async (event) => {
        event.preventDefault();
        if (productSearchData && productSearchData.length > 0) {
            setModalOpen(true);
        }
    };
    const productGet = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${productSearch}?search=${productSearchData}`);
            // setProductSearch(response?.data?.result);
            setShowSearchResults(true);
        } catch (error) {
            console.error('Error fetching product search:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        productGet()

    }, [productSearchData])

    const [isSearch, setIsSearch] = useState(false);
    const openModalOnClick1 = (e) => {
        const inputValue = e.target.value;
        if (inputValue.length > 0) {  // Set your desired length threshold
            setIsSearch(true);
        }
        // else {
        //     setIsSearch(false);
        // }
    };

    const openModalOnClick = () => {
        setModalOpen(true);
        setLogoutValue(false)
    };

    const [searchKey, setSearchKey] = useState('')
    const [loads, setLoads] = useState(false)

    const handleInputBlur = () => {
        setSearchKey('');
        setIsSearch(false);
    };


    const productGet1 = async (searchKey) => {
        try {
            setLoads(true);
            const response = await axios.get(`${productSearch}?search=${searchKey}`);
            setProductSearch(response?.data?.result);
        } catch (error) {
            console.error('Error fetching product search:', error);
        } finally {
            setLoads(false);
        }
    };

    useEffect(() => {
        productGet1(searchKey)

    }, [searchKey])

    // const navToProductView = (product_url) => {
    //     navigate(`/products/viewProduct/${product_url}`);
    //     toggle()
    // };

    const navToProductView = (product_url) => {
        navigate(`/products/viewProduct/${product_url}`);
    };

    const wishlist = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${wishList}`)
            setWishlistData(response?.data?.result)
        } catch (error) {

        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (localStorage.getItem('token')) {
            additionalData()
            wishlist()
        }

    }, [localStorage.getItem('token')])

    const [menuData, setMenuData] = useState([]);


    const handleMenuData = async () => {
        try {
            const response = await axios.get(menuProduct);
            setMenuData(response?.data?.result);
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    }

    useEffect(() => {
        handleMenuData()
    }, [])

    const [menus, setMenus] = useState();

    const openShow = (id) => {
        getMenu(id)
        setIsShown(true)
    }

    const getMenu = async (id) => {
        try {
            const response = await axios.get(`${menuProduct}/${id}`)
            setMenus(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }

    }


    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showTabCount, setShowTabCount] = useState(8);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        // Clean up the event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (windowWidth > 1570) {
            setShowTabCount(10)
        } if (windowWidth < 1540) {
            setShowTabCount(7)
        } if (windowWidth < 1530) {
            setShowTabCount(7)
        } if (windowWidth < 1400) {
            setShowTabCount(6)
        } if (windowWidth < 1300) {
            setShowTabCount(5)
        }
    }, [windowWidth])

    const handleMenuProductClick = (menu_url) => {
        window.location.href = `/menuProducts/${menu_url}`;

    };
    // const closeShow = () => {
    //     setIsShown(false);
    //   };

    const location = useLocation();

    const getNavLinkClass = (path) => {
        return location.pathname === path ? 'nav-top-header-head1-active-link' : 'nav-top-header-head1';
    };

    const getNavLinkClass1 = (path) => {
        return location.pathname === path ? 'nav-top-header-head1-active-link2' : 'nav-top-header-head12';
    };

    const getNavLinkClass2 = (path) => {
        return location.pathname === path ? 'nav-top-header-head1-active-link3' : 'nav-top-header-head13';
    };

    const getNavLinkClass3 = (path) => {
        return location.pathname === path ? 'cart-count2 mb-4 position-absolute' : 'cart-count mb-4 position-absolute';
    };


    const handleWishlistClick = () => {
        if (!isLogin) {
            // toast.error('Please login');
            toggleEmail();
        } else {
            navigate(`/myWishList`);
        }
    };

    const handleCartClick = () => {
        if (!isLogin) {
            // toast.error('Please login');
            toggleEmail();
        } else {
            navigate(`/myCart`);
        }
    };

    const handleClickOutside = (event) => {
        if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
            handleInputBlur(); // Call your blur handler to close the search results
        }
    };

    // Add event listener for clicks outside the component
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const divRef = useRef(null); // Reference to the div

    // Handle clicks outside the div
    useEffect(() => {
        const handleClickOutside = (event) => {
            // If clicked outside the div, set isActiveWishlist to false
            if (divRef.current && !divRef.current.contains(event.target)) {
                setActiveWhislist(false);
            }
        };

        // Add event listener when the component is mounted
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener when the component is unmounted
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [divRef]);

    const divRef2 = useRef(null); // Reference to the div

    // Handle clicks outside the div
    useEffect(() => {
        const handleClickOutside = (event) => {
            // If clicked outside the div, set isActiveWishlist to false
            if (divRef2.current && !divRef2.current.contains(event.target)) {
                setActiveCart(false);
            }
        };

        // Add event listener when the component is mounted
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener when the component is unmounted
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [divRef2]);


    const navToFunct1 = () => {
        if (wishlistProps?.length > 0) {
            navigate('/myWishList')
        } else {
            navigate('/products')
        }
    }
    const navToFunct2 = () => {
        if (wishlistData?.length > 0) {
            navigate('/myWishList')
        } else {
            navigate('/products')
        }
    }

    const navToFunctCart1 = () => {
        if (cartData?.products?.length > 0) {
            navigate('/myCart')
        } else {
            navigate('/products')
        }
    }
    const navToFunctCart2 = () => {
        if (dataa?.products?.length > 0) {
            navigate('/myCart')
        } else {
            navigate('/products')
        }
    }

    console.log(cartData);

    return (
        <>
            <div style={{ position: 'relative' }} className='navbar-con'>
                <div className='d-flex'>
                    <div className='nav-logo'>
                        <Link to='/'>
                            <img src={officialLogo} className='mt-3 official-logo' alt="logo" />
                        </Link>
                    </div>
                    <div className='nav-top-hide'>
                        <div ref={searchContainerRef} >
                            <form className="search-container"
                                onSubmit={handleSearchSubmit}
                            >
                                <input
                                    style={{ color: 'black', border: '1px solid #BCBCBC' }}
                                    type="text"
                                    id="search-bar"
                                    autoComplete="off"
                                    placeholder="Business Cards, Posters, Booklets..."
                                    onChange={(e) => {
                                        setSearchKey(e.target.value);
                                        openModalOnClick1(e);
                                    }}
                                    onClick={openModalOnClick1}
                                // onBlur={handleInputBlur}

                                // onChange={(e) => handleSearchInputChange(e)}
                                />
                                <span className="search-icon"><GoSearch className="search-icon1" /></span>
                            </form>

                            {isSearch ? (
                                <div className="search-results">
                                    <div className='search-drop' style={{ position: 'absolute', zIndex: '9999', background: 'white', width: '500px', borderRadius: '4px' }}>
                                        {productSearchData.slice(0, 5).map((productData, index) => ( // Limit to 5 items
                                            <Card
                                                key={productData?._id}
                                                style={{
                                                    cursor: "pointer",
                                                    border: 'none',
                                                    backgroundColor: index === 0 ? '#FFEFE6' : 'white',
                                                    height: '60px'
                                                }}
                                                className=''
                                                onClick={() => navToProductView(productData?.product_url)}
                                            >
                                                <div className='d-flex' style={{ width: "100%", marginTop: '-5px' }}>
                                                    <div className='p-3'>
                                                        <img
                                                            src={productData?.image}
                                                            style={{ height: "40px", width: '50px', borderRadius: '2px' }}
                                                            className='img-fluid'
                                                            alt='img'
                                                        />
                                                    </div>
                                                    <div className='d-flex justify-content-between p-2' style={{ width: "100%" }}>
                                                        <div className='d-flex flex-column'>
                                                            <p style={{ font: 'Lato', fontWeight: '400', fontSize: '16px', lineHeight: '24px' }} className='mt-2'>{productData?.name}</p>
                                                            <p style={{ font: 'Lato', fontWeight: '500', fontSize: '12px', color: '#E4510B', marginTop: '-20px', lineHeight: '24px' }}>in {productData?.productCategory?.name}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        ))}
                                    </div>
                                </div>
                            ) : null}



                        </div>

                    </div>
                </div>
                <div className='d-none d-lg-block'>
                    <div className='d-flex nav-top-header'>
                        {/* <NavLink
                            to="/"
                        >
                            <div className='d-flex mt-3'>
                                <h3 className={getNavLinkClass('/')}>Home</h3>
                            </div>
                        </NavLink>
                        {/* <NavLink
                            to="/aboutus"
                        >
                            <div className='d-flex mt-3'>
                                <h3 className={getNavLinkClass('/aboutus')}>About Us</h3>
                            </div>
                        </NavLink> */}
                        <div className='d-flex mt-3'>
                            <h3 onClick={() => setIsStartPrinting(true)} style={{ color: '#E4510B', cursor: 'pointer' }} className={getNavLinkClass('')}>Start Printing</h3>
                        </div>
                        <NavLink>
                            {role ? (
                                <UncontrolledDropdown tag="li" className="dropdown-user nav-item ss">
                                    <DropdownToggle
                                        href="/"
                                        tag="a"
                                        className="nav-link dropdown-user-link pe-4"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        <div style={{ border: '1px solid #F3B9A1', borderRadius: '4px', padding: '5px 10px 5px 10px', paddingRight: '15px', height: '55px' }} className='d-flex align-items-center justify-content-center'>
                                            <span style={{ color: '#515151' }} className='pe-2'><CgProfile style={{ color: '#515151' }} size={25} /></span>
                                            <h3 className='nav-top-header-head mt-1'>
                                                {name === "undefined" ? (`***${mobile.slice(6)}`) : (name)}
                                                <RiArrowDropDownLine />
                                            </h3>
                                        </div>
                                    </DropdownToggle>
                                    <DropdownMenu end>
                                        {role === 'USER' ? (
                                            <>
                                                <Link to='/MyOrder'>
                                                    <DropdownItem tag='a'><ShoppingBag style={{ color: 'black' }} /> My Order</DropdownItem>
                                                </Link>
                                                <hr className='m-0 p-0' />
                                                <Link to='/MyCart'>
                                                    <DropdownItem tag='a'><ShoppingCart style={{ color: 'black' }} /> My Cart</DropdownItem>
                                                </Link>
                                                <hr className='m-0 p-0' />
                                                {/* <Link to='/myQueries'>
                                                    <DropdownItem tag='a'><User style={{ color: 'black' }} /> My Queries</DropdownItem>
                                                </Link>
                                                <hr className='m-0 p-0' /> */}
                                                <Link to='/myWishList'>
                                                    <div className='d-flex d-lg-none'>
                                                        <DropdownItem tag='a'><Heart style={{ color: 'black' }} /> My WishList</DropdownItem>
                                                        {numberOfData ? (
                                                            <div className='cart-count mt-1 pe-4' style={{ backgroundColor: 'white', color: 'black' }}>
                                                                ({numberOfData})
                                                            </div>
                                                        ) : (
                                                            null
                                                        )}
                                                    </div>
                                                </Link>
                                                <hr className='m-0 p-0' />
                                                <Link to='/MyProfile'>
                                                    <DropdownItem tag='a'><User style={{ color: 'black' }} /> My Profile</DropdownItem>
                                                </Link>
                                                <hr className='m-0 p-0' />
                                                <Link to='/'>
                                                    <DropdownItem tag='a' onClick={logout} className="text-danger"><LogOut /> Log Out</DropdownItem>
                                                </Link>
                                            </>
                                        ) : (
                                            <>
                                                <Link to='/admin/dashboard'>
                                                    <DropdownItem tag='a'><TrendingUp style={{ color: 'black' }} /> DashBoard</DropdownItem>
                                                </Link>
                                                <hr className='m-0 p-0' />
                                                {/* <Link to='/admin/'>
                                        <DropdownItem tag='a'>Orders</DropdownItem>
                                    </Link> */}
                                                <Link to='/admin/productCategory'>
                                                    <DropdownItem tag='a'><BookOpen style={{ color: 'black' }} /> Product Category</DropdownItem>
                                                </Link>
                                                <hr className='m-0 p-0' />
                                                <Link to='/admin/product'>
                                                    <DropdownItem tag='a'><Box style={{ color: 'black' }} /> Product</DropdownItem>
                                                </Link>
                                                <hr className='m-0 p-0' />
                                                <Link to='/admin/offerProduct'>
                                                    <DropdownItem tag='a'><Percent style={{ color: 'black' }} />Offer Product</DropdownItem>
                                                </Link>
                                                <hr className='m-0 p-0' />
                                                <Link to='/admin/fieldType'>
                                                    <DropdownItem tag='a'><File style={{ color: 'black' }} /> Field</DropdownItem>
                                                </Link>
                                                <hr className='m-0 p-0' />
                                                <Link to='/admin/menu'>
                                                    <DropdownItem tag='a'><Home style={{ color: 'black' }} /> Menu Combo</DropdownItem>
                                                </Link>
                                                <hr className='m-0 p-0' />
                                                <Link to='/admin/newstiker'>
                                                    <DropdownItem tag='a'><Newspaper style={{ color: 'black' }} /> News Ticker</DropdownItem>
                                                </Link>
                                                <hr className='m-0 p-0' />

                                                {/* <Link to='/admin/report'>
                                                    <DropdownItem tag='a'><FileText style={{ color: 'black' }} /> Report</DropdownItem>
                                                </Link>
                                                <hr className='m-0 p-0' /> */}
                                                {/* <Link to='/admin/fieldOption'>
                                                    <DropdownItem tag='a'><AlignCenter style={{ color: 'black' }} /> Field Option</DropdownItem>
                                                </Link>
                                                <hr className='m-0 p-0' /> */}
                                                <Link to='/'>
                                                    <DropdownItem tag='a' onClick={logout} className="text-danger"><LogOut /> Log Out</DropdownItem>
                                                </Link>
                                            </>
                                        )}

                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            ) : (
                                <div className='pe-4'>
                                    <div style={{ border: '1px solid #F3B9A1', borderRadius: '4px', padding: '10px 20px 10px 20px', alignItems: 'center', paddingRight: '15px', height: '55px' }} className='d-flex' onClick={toggleEmail}>
                                        <span className='pe-2'><CgProfile style={{ color: '#515151' }} size={30} /></span>
                                        <h3 className='nav-top-header-head pe-2'>Login</h3>
                                    </div>
                                </div>
                            )}
                        </NavLink>
                        {/* <Link className='pe-4' to='/myWishList'> */}
                        {wishlistProps ? (
                            <div ref={divRef} onClick={() => setActiveWhislist(!isActiveWishlist)} style={{ position: 'relative', border: '1px solid #F3B9A1', borderRadius: '4px', alignItems: 'center', width: '55px', height: '55px', cursor: 'pointer' }} className={getNavLinkClass1('/myWishList')} >
                                <span style={{ color: 'black', position: 'relative' }}><Heart className={getNavLinkClass2('/myWishList')} size={30} /></span>
                                {wishlistProps?.length ? (
                                    <div className={getNavLinkClass3('/myWishList')}>
                                        {wishlistProps?.length}
                                    </div>
                                ) : (
                                    null
                                )}
                                {isActiveWishlist && (
                                    <div className='whislist-con'>
                                        {wishlistProps?.slice(0, 3).map((item, i) => (
                                            <Card
                                                key={item?._id}
                                                style={{
                                                    cursor: "pointer",
                                                    border: 'none',
                                                    // backgroundColor: i === 0 ? '#FFEFE6' : 'white',
                                                    height: '60px'
                                                }}
                                                className=''
                                                onClick={() => navToProductView(item?.product?.product_url)}
                                            >
                                                <div className='d-flex' style={{ width: "100%", }}>
                                                    <div className=''>
                                                        <img
                                                            src={item?.product?.image}
                                                            style={{ height: "40px", width: '50px', borderRadius: '2px' }}
                                                            className='img-fluid'
                                                            alt='img'
                                                        />
                                                    </div>
                                                    <div className='d-flex justify-content-between ms-2' style={{ width: "100%" }}>
                                                        <div className='d-flex flex-column'>
                                                            <p style={{ font: 'Lato', fontWeight: '400', fontSize: '16px', lineHeight: '24px' }} >{item?.product?.name}</p>
                                                            <p style={{ font: 'Lato', fontWeight: '500', fontSize: '12px', color: '#E4510B', marginTop: '-20px', lineHeight: '24px' }}>in {item?.product?.productCategory?.name}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        ))}
                                        {wishlistProps?.length === 0 && (
                                            // <div className='wishlist-con'>
                                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                                <span className='mb-1'><AlertCircle size={40} /></span>
                                                <p>Your Wishlist is Empty</p>
                                            </div>
                                            // </div>
                                        )}
                                        <button onClick={navToFunct1} className='wishlist-btn-new mt-3'>{wishlistProps?.length > 0 ? 'View All Items' : 'Continue Shopping'} </button >
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div ref={divRef} onClick={() => setActiveWhislist(!isActiveWishlist)} style={{ position: 'relative', border: '1px solid #F3B9A1', borderRadius: '4px', alignItems: 'center', width: '55px', height: '55px', cursor: 'pointer' }} className={getNavLinkClass1('/myWishList')} >
                                <span style={{ color: 'black', position: 'relative' }}><Heart className={getNavLinkClass2('/myWishList')} size={30} /></span>
                                {wishlistData?.length ? (
                                    <div className={getNavLinkClass3('/myWishList')}>
                                        {wishlistData?.length}
                                    </div>
                                ) : (
                                    null
                                )}
                                {isActiveWishlist && (
                                    <div className='whislist-con'>
                                        {wishlistData?.slice(0, 3).map((item, i) => (
                                            <Card
                                                key={item?._id}
                                                style={{
                                                    cursor: "pointer",
                                                    border: 'none',
                                                    // backgroundColor: i === 0 ? '#FFEFE6' : 'white',
                                                    height: '60px'
                                                }}
                                                className=''
                                                onClick={() => navToProductView(item?.product?.product_url)}
                                            >
                                                <div className='d-flex' style={{ width: "100%", }}>
                                                    <div className=''>
                                                        <img
                                                            src={item?.product?.image}
                                                            style={{ height: "40px", width: '50px', borderRadius: '2px' }}
                                                            className='img-fluid'
                                                            alt='img'
                                                        />
                                                    </div>
                                                    <div className='d-flex justify-content-between ms-2' style={{ width: "100%" }}>
                                                        <div className='d-flex flex-column'>
                                                            <p style={{ font: 'Lato', fontWeight: '400', fontSize: '16px', lineHeight: '24px' }} >{item?.product?.name}</p>
                                                            <p style={{ font: 'Lato', fontWeight: '500', fontSize: '12px', color: '#E4510B', marginTop: '-20px', lineHeight: '24px' }}>in {item?.product?.productCategory?.name}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        ))}
                                        {wishlistData?.length === 0 && (
                                            // <div className='wishlist-con'>
                                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                                <span className='mb-1'><AlertCircle size={40} /></span>
                                                <p>Your Wishlist is Empty</p>
                                            </div>
                                            // </div>
                                        )}
                                        <button onClick={navToFunct2} className='wishlist-btn-new mt-3'>{wishlistData?.length > 0 ? 'View All Items' : 'Continue Shopping'} </button >
                                    </div>
                                )}
                            </div>
                        )}

                        {/* </Link> */}
                        {/* <Link to='/myCart'> */}
                        {cartData ? (
                            <div ref={divRef2} onClick={() => setActiveCart(!isActiveCart)} style={{ position: 'relative', border: '1px solid #F3B9A1', borderRadius: '4px', alignItems: 'center', width: '55px', height: '55px', marginLeft: '25px', cursor: 'pointer' }} className={getNavLinkClass1('/myCart')}>
                                <span style={{ color: 'black', position: 'relative' }}> <ShoppingCart className={getNavLinkClass2('/myCart')} size={30} /></span>
                                {cartData?.products?.length ? (
                                    <div className={getNavLinkClass3('/myCart')}>
                                        {cartData?.products?.length}
                                    </div>
                                ) : (
                                    null
                                )}
                                {isActiveCart && (
                                    <div className='cart-con'>
                                        {cartData?.products?.map((item, i) => (
                                            <div key={i} className='d-flex justify-content-between align-items-center'>
                                                <div className='d-flex'>

                                                    <img className='cart-img-box' src={item?.file?.[0] || item?.customizeImages?.[0]} alt='icon' />
                                                    <div className='ms-2'>
                                                        <p className='p-0 m-0 text-dark'>{item?.product?.name}</p>
                                                        <p style={{ fontWeight: '500', fontSize: '12px', color: '#E4510B', }}>in {item?.product?.productCategory?.name}</p>
                                                    </div>
                                                </div>
                                                <p className='text-success'>₹{parseInt(item?.amount)}</p>
                                            </div>
                                        ))}
                                        {cartData?.products?.length === 0 ? (
                                            <div className='d-flex flex-column justify-content-center align-items-center text-dark'>
                                                <span className='mb-1'><AlertCircle size={40} /></span>
                                                <p>Your Cart is Empty</p>
                                            </div>
                                        ) : (
                                            <>
                                                <div style={{ width: '100%', height: '1px', backgroundColor: 'gray', marginBottom: '8px' }}></div>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <h1 className='text-dark h5'>Total</h1>
                                                    <h1 className='text-success h5'>₹{parseInt(cartData?.totalAmount)}</h1>
                                                </div>
                                            </>
                                        )}
                                        <button onClick={navToFunctCart1} className='wishlist-btn-new'>{cartData?.products?.length > 0 ? 'View Cart' : 'Continue Shopping'} </button >
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div ref={divRef2} onClick={() => setActiveCart(!isActiveCart)} style={{ position: 'relative', border: '1px solid #F3B9A1', borderRadius: '4px', alignItems: 'center', width: '55px', height: '55px', marginLeft: '25px', cursor: 'pointer' }} className={getNavLinkClass1('/myCart')}>
                                <span style={{ color: 'black', position: 'relative' }}> <ShoppingCart className={getNavLinkClass2('/myCart')} size={30} /></span>
                                {numberOfItem ? (
                                    <div className={getNavLinkClass3('/myCart')}>
                                        {numberOfItem}
                                    </div>
                                ) : (
                                    null
                                )}
                                {isActiveCart && (
                                    <div className='cart-con'>
                                        {dataa?.products?.map((item, i) => (
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div className='d-flex'>
                                                    <img className='cart-img-box' src={item?.file?.[0] || item?.customizeImages?.[0]} alt='icon' />
                                                    <div className='ms-2'>
                                                        <p className='p-0 m-0 text-dark'>{item?.product?.name}</p>
                                                        <p style={{ fontWeight: '500', fontSize: '12px', color: '#E4510B', }}>in {item?.product?.productCategory?.name}</p>
                                                    </div>
                                                </div>
                                                <p className='text-success'>₹{parseInt(item?.amount)}</p>
                                            </div>
                                        ))}
                                        {localStorage.getItem('token') ? (
                                            <>
                                                {dataa?.products?.length === 0 ? (
                                                    <div className='d-flex flex-column justify-content-center align-items-center text-dark'>
                                                        <span className='mb-1'><AlertCircle size={40} /></span>
                                                        <p>Your Cart is Empty</p>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div style={{ width: '100%', height: '1px', backgroundColor: 'gray', marginBottom: '8px' }}></div>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <h1 className='text-dark h5'>Total</h1>
                                                            <h1 className='text-success h5'>₹{parseInt(dataa?.totalAmount)}</h1>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <div className='d-flex flex-column justify-content-center align-items-center text-dark'>
                                                <span className='mb-1'><AlertCircle size={40} /></span>
                                                <p>Your Cart is Empty</p>
                                            </div>
                                        )}
                                        <button onClick={navToFunctCart2} className='wishlist-btn-new'>{dataa?.products?.length > 0 ? 'View Cart' : 'Continue Shopping'} </button >
                                    </div>
                                )}
                            </div>
                        )}

                        {/* </Link> */}
                    </div>
                </div >
            </div >

            <div style={{ position: 'sticky', top: -1, zIndex: 100 }}>
                <div className='nav-mobile ss' >
                    <nav>
                        <div className='d-lg-none'>
                            <div className='nav-mobile-flex'>
                                <div className='d-flex'>
                                    <div className={`toggle-wrap ${menuActive ? 'active' : ''} mt-3`} onClick={toggleMenu}>
                                        <span className="toggle-bar"></span>
                                    </div>
                                    <h2 style={{ color: 'white', fontWeight: '400' }} className='pt-4'>Products</h2>
                                </div>
                                <div className='d-flex mt-3'>
                                    <div onClick={openModalOnClick} style={{ marginTop: "10px", marginRight: "3px" }}>
                                        <span className='pe-3' style={{ color: "white" }}> <Search /></span>
                                    </div>
                                    <div className='cart-link me-3' >
                                        <Link to='/myCart'>
                                            <div className='d-flex'>
                                                <span style={{ color: 'white' }} className='pe-1 mt-2'><ShoppingCart size={25} /></span>
                                                {numberOfItem ? (
                                                    <div className='cart-count mb-4' style={{ backgroundColor: 'white', color: 'black' }}>
                                                        {numberOfItem}
                                                    </div>
                                                ) : (
                                                    null
                                                )}

                                            </div>
                                        </Link>
                                    </div>
                                    {role ? (
                                        <UncontrolledDropdown style={{ marginTop: '5px' }} tag="li" className="dropdown-user nav-item">
                                            <DropdownToggle
                                                href="/"
                                                tag="a"
                                                className="nav-link dropdown-user-link"
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                <div className='d-flex mt-1'>
                                                    <span style={{ color: 'white' }} className=''><User size={25} /></span>
                                                    {/* <h3 className='nav-top-header-head'>{role}</h3> */}
                                                </div>
                                            </DropdownToggle>
                                            <DropdownMenu className='mobile-dropdown-auth' end>
                                                {role === 'USER' ? (
                                                    <>
                                                        <Link to='/MyOrder'>
                                                            <DropdownItem tag='a'><ShoppingCart /> My Order</DropdownItem>
                                                        </Link>
                                                        <hr className='m-0 p-0' />
                                                        <Link to='/MyCart'>
                                                            <DropdownItem tag='a'><ShoppingCart /> My Cart</DropdownItem>
                                                        </Link>
                                                        <hr className='m-0 p-0' />
                                                        <Link to='/myWishList'>
                                                            <div className='d-flex'>
                                                                <DropdownItem tag='a'><Heart style={{ color: 'black' }} /> My WishList</DropdownItem>
                                                                {numberOfData ? (
                                                                    <div className='cart-count mt-1 pe-4' style={{ backgroundColor: 'white', color: 'black' }}>
                                                                        ({numberOfData})
                                                                    </div>
                                                                ) : (
                                                                    null
                                                                )}
                                                            </div>
                                                        </Link>
                                                        <hr className='m-0 p-0' />
                                                        {/* <Link to='/myQueries'>
                                                            <DropdownItem tag='a'><User style={{ color: 'black' }} /> My Queries</DropdownItem>
                                                        </Link>
                                                        <hr className='m-0 p-0' /> */}

                                                        <Link to='/MyProfile'>
                                                            <DropdownItem tag='a'><User /> My Profile</DropdownItem>
                                                        </Link>
                                                        <hr className='m-0 p-0' />
                                                        <Link to='/'>
                                                            <DropdownItem tag='a' onClick={logout} className="text-danger"><LogOut /> Log Out</DropdownItem>
                                                        </Link>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Link to='/admin/dashboard'>
                                                            <DropdownItem tag='a'><TrendingUp /> DashBoard</DropdownItem>
                                                        </Link>
                                                        <hr className='m-0 p-0' />
                                                        {/* <Link to='/admin/'>
                                        <DropdownItem tag='a'>Orders</DropdownItem>
                                    </Link> */}
                                                        <Link to='/admin/productCategory'>
                                                            <DropdownItem tag='a'><BookOpen /> Product Category</DropdownItem>
                                                        </Link>
                                                        <hr className='m-0 p-0' />
                                                        <Link to='/admin/product'>
                                                            <DropdownItem tag='a'><Box /> Product</DropdownItem>
                                                        </Link>
                                                        <hr className='m-0 p-0' />
                                                        <Link to='/admin/offerProduct'>
                                                            <DropdownItem tag='a'><Percent />Offer Product</DropdownItem>
                                                        </Link>
                                                        <hr className='m-0 p-0' />
                                                        <Link to='/admin/fieldType'>
                                                            <DropdownItem tag='a'><File /> Field</DropdownItem>
                                                        </Link>
                                                        <hr className='m-0 p-0' />
                                                        <Link to='/admin/menu'>
                                                            <DropdownItem tag='a'><Home style={{ color: 'black' }} /> Menu Combo</DropdownItem>
                                                        </Link>
                                                        <hr className='m-0 p-0' />
                                                        <Link to='/admin/newstiker'>
                                                            <DropdownItem tag='a'><Newspaper style={{ color: 'black' }} /> News Ticker</DropdownItem>
                                                        </Link>
                                                        <hr className='m-0 p-0' />                                                        {/* <Link to='/admin/fieldOption'>
                                                            <DropdownItem tag='a'><AlignCenter /> Field Option</DropdownItem>
                                                        </Link>
                                                        <hr className='m-0 p-0' /> */}
                                                        <Link to='/'>
                                                            <DropdownItem tag='a' onClick={logout} className="text-danger"><LogOut />Log Out</DropdownItem>
                                                        </Link>
                                                    </>
                                                )}

                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    ) : (
                                        <div className='d-flex mt-2' onClick={toggleEmail} style={{ cursor: 'pointer' }}>
                                            <span style={{ color: 'white' }} className=''><User size={25} /></span>
                                            {/* <h3 className='nav-top-header-head'>Login / Signup</h3> */}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='d-none d-lg-block' style={{ position: 'relative' }}>
                            {/* <hgroup>
                                <div className='nav-left-con mt-3'>

                                    <NavLink style={{ textDecoration: 'none' }}>
                                        <div className='d-flex'>
                                            {menuData && menuData?.rows?.slice(0, showTabCount)?.map((item, i) => (
                                                <div className='d-flex'>
                                                    <p className='nav-second-head mt-2' key={item._id}
                                                        onMouseEnter={() => openShow(item?._id)}
                                                        onClick={() => handleMenuProductClick(item?.menu_url)}
                                                    >{item?.name}</p>
                                                    {showTabCount !== i + 1 && (
                                                        <div className='vertical-line'></div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </NavLink>
                                </div>
                            </hgroup>
                            <div className='shadow menus-container' style={{ position: 'absolute ', width: '100%', marginTop: '8px', borderRadius: '20px' }}>
                                {isShown && (
                                    <div>
                                        <HomeMenu setIsShown={setIsShown} menus={menus} />
                                    </div>
                                )}
                            </div> */}
                            <hgroup>
                                <div className='nav-left-con mt-3' style={{ position: 'relative' }}>
                                    <NavLink style={{ textDecoration: 'none' }}>
                                        <div className='d-flex'>
                                            {menuData && menuData?.rows?.slice(0, showTabCount)?.map((item, i) => (
                                                <div className='d-flex' key={item._id}>
                                                    <p className='nav-second-head mt-2'
                                                        onMouseEnter={() => openShow(item?._id)}
                                                        onClick={() => handleMenuProductClick(item?.menu_url)}
                                                        style={{ position: 'relative' }} // Make the word position relative
                                                    >
                                                        {item?.name}
                                                    </p>
                                                    {showTabCount !== i + 1 && (
                                                        <div className='vertical-line'></div>
                                                    )}

                                                    {/* Popup Display */}
                                                    {isShown && (
                                                        <div className='shadow menus-container'
                                                            style={{
                                                                position: 'absolute',
                                                                top: '100%',
                                                                left: '50%',
                                                                transform: 'translateX(-50%)',
                                                                marginTop: '8px',
                                                                borderRadius: '20px',
                                                                zIndex: 10
                                                            }}>
                                                            <HomeMenu setIsShown={setIsShown} menus={menus} />
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </NavLink>
                                </div>
                            </hgroup>

                        </div>
                    </nav>
                    <aside className={menuActive ? 'active' : ''}>
                        <a href="#" >
                            {menuData && menuData?.rows?.map(detail => (
                                <p className='mt-4' key={detail?._id}
                                    onClick={() => handleMenuProductClick(detail?.menu_url)}
                                >{detail?.name}</p>
                            ))}
                        </a>

                    </aside>
                </div >
            </div >

            {/* <HomeMenu isShown={isShown} setIsShown={setIsShown} menus={menus} /> */}
            {/* <MobileOTPModal isOpen={modal} toggleOTP={toggle} logout={logoutValue} /> */}
            <FinalAuthModal isOpen={isToggle} toggle={toggleEmail} />
            <ChooseProductModal isOpen={isStartPrinting} toggle={() => setIsStartPrinting(!isStartPrinting)} />
        </>
    )
}

export default Navbar

import React, { useEffect, useState } from 'react'
import Navbar from '../navbar'
import './index.css';
import FooterSection from '../footer'
import axios from 'axios'
import { profile, profileEdit, shippingAddress } from '../../ApiConfigs/ApiConfig'
import { Button, Col, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { Edit3, User } from 'react-feather'
import mobile from '../../assets/images/banner/Group 1410109338.png';
import email from '../../assets/images/banner/Asset 1.png';
import name from '../../assets/images/banner/Group 1410109339 (1).png';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { FaPlus } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import { FaChevronDown } from "react-icons/fa";
import { X } from 'react-feather'


function MyProfile() {
    const [data, setData] = useState('')
    const [modalOpen, setModalOpen] = useState(false);
    const [mobileModalOpen, setMobileModalOpen] = useState(false);
    const [emailModalOpen, setEmailModalOpen] = useState(false);

    const navigate = useNavigate();

    const formSchema = yup.object().shape({
        name: yup.string().required('Please Enter Name'),
        email: yup.string().required('Please Enter Email'),

    });
    const {
        reset,
        control,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(formSchema),
        defaultValue: {
            name: data?.name || "",
            email: data?.email || "",
        }
    });


    const submitForm = async (payload) => {
        try {
            const response = await axios.put(profileEdit, payload);
            getProfile();
            toast.success(response?.data?.result?.msg);
            reset();
            toggleModal();
            navigate('/MyProfile');
        } catch (error) {
            toast.error(error.response?.data?.msg);
        }
    };


    const [showAll, setShowAll] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedAddressId, setSelectedAddressId] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [addAddressModal, setAddAddressModal] = useState(false);
    const [getProfile1, setProfile] = useState('')
    const displayedAddresses = showAll ? getProfile1?.shippingAddress : getProfile1?.shippingAddress?.slice(0, 4);

    useEffect(() => {
        // additionalData()
        myProfileDatails()
    }, [])

    const myProfileDatails = async () => {
        try {
            const response = await axios.get(profile)
            setProfile(response?.data?.result)
        } catch (error) {

        }
    }

    const handleRadioChange = (index, id) => {
        setSelectedIndex(index);
        setSelectedAddressId(id);
    };

    const openEditModal = (address) => {
        setSelectedAddress(address);
        setAddAddressModal(true); // Assuming this is the state to open the modal
    };

    const handleAddressDelete = async (addressId) => {
        try {
            const response = await axios.delete(`${shippingAddress}/${getProfile1?._id}`, {
                params: {
                    address_id: addressId
                }
            });
            // additionalData();
            myProfileDatails();
            toast.success(response?.data?.msg);
        } catch (error) {
            toast.error(error?.response?.data?.msg || "Failed to delete address");
        }
    };

    const openAddAddressModal = () => {
        setSelectedAddress(null);
        setAddAddressModal(true);
    }

    const closeAddAddressModal = () => {
        reset();
        setSelectedAddress(null);
        setAddAddressModal(false);
    };

    useEffect(() => {
        if (selectedAddress) {
            setValue('firstName', selectedAddress.firstName || '');
            setValue('lastName', selectedAddress.lastName || '');
            setValue('state', selectedAddress.state || '');
            setValue('city', selectedAddress.city || '');
            setValue('landmark', selectedAddress.landmark || '');
            setValue('address', selectedAddress.address || '');
            setValue('pincode', selectedAddress.pincode || '');
            setValue('mobile', selectedAddress.mobile || '');
            setValue('alternateMobile', selectedAddress.alternateMobile || '');
            setValue('defaultAddress', selectedAddress.defaultAddress || false);
        } else {
            reset(); // Reset the form when adding a new address
        }
    }, [selectedAddress, setValue, reset]);

    // email
    const submitEmailForm = async (payload) => {
        try {
            const response = await axios.put(profileEdit, payload);
            getProfile();
            toast.success(response?.data?.result?.msg);
            reset();
            emailModal();
            navigate('/MyProfile');
        } catch (error) {
            toast.error(error.response?.data?.msg);
        }
    };


    // profile get
    const getProfile = async () => {
        try {
            const response = await axios.get(profile)
            setData(response?.data?.result)
        } catch (error) {

        }
    }

    // profile edit
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    const mobileModal = () => {
        setMobileModalOpen(!mobileModalOpen);
    };
    const emailModal = () => {
        setEmailModalOpen(!emailModalOpen)
    };



    useEffect(() => {
        getProfile()
    }, ['']);

    useEffect(() => {
        reset({
            name: data?.name,
            email: data?.email,
        });
    }, [reset, setValue]);
    return (
        <div>
            <Navbar />
            <div className='pt-5 ' style={{ paddingLeft: '8%', paddingRight: '8%', paddingBottom: '10%' }}>
                <h1>My Profile</h1>
                <Row>
                    <h3 className='mt-5'><User size={40} /> Profile Information</h3>
                    <Col lg={3} md={6} sm={6}>
                        <div className='mt-4' style={{ textAlign: 'start' }}>
                            <h6 className='mb-4'>Name</h6>
                            <h6 className='mb-4'>Mobile Number</h6>
                            <h6 className='mb-4'>Email Address</h6>
                        </div>
                    </Col>
                    <Col lg={9} md={6} sm={6}>
                        <div className='mt-4' style={{ textAlign: 'start' }}>
                            <h6 className='mb-4'>{data?.name || "Enter your Name"} <span onClick={toggleModal}><Edit3 style={{ cursor: 'pointer' }} /></span></h6>
                            <h6 className='mb-4'>{data?.mobile || "Mobile Number not Provided"} <span onClick={mobileModal}><Edit3 style={{ cursor: 'pointer' }} /></span></h6>
                            <h6>{data?.email || "Enter your Email"} <span onClick={emailModal}><Edit3 style={{ cursor: 'pointer' }} /></span></h6>
                        </div>
                    </Col>
                </Row>
                <Col lg={12} md={12} sm={12} >
                    {getProfile1?.shippingAddress?.length > 0 ? (
                        <div className='mt-5' style={{ paddingRight: '2%' }}>
                            <div style={{ paddingLeft: '3%', paddingRight: '3%', paddingTop: '3%', border: '1px solid #F0F0F0', borderRadius: '15px', boxShadow: 'rgba(0, 0, 0, 0.05)' }}>
                                <div style={{ borderBottom: '1px solid #F0F0F0', paddingBottom: '20px' }}>
                                    <p style={{ color: '#2B2B2D', fontSize: '20px', lineHeight: '24px', fontWeight: '700' }}>Delivery Address</p>
                                </div>
                                {displayedAddresses?.map((address, index) => (
                                    <div key={address?._id} style={{ borderBottom: '1px solid #F0F0F0' }}>
                                        <div className='d-flex pe-4 mt-4'>
                                            <div>
                                                <input
                                                    className='radio mt-1 me-3'
                                                    type="radio"
                                                    name="address"
                                                    checked={selectedIndex === index}
                                                    onChange={() => handleRadioChange(index, address._id)}
                                                />
                                            </div>
                                            <div>
                                                <h5>{address?.firstName} {address?.lastName}, {address?.mobile}</h5>
                                                <p className=' d-flex align-items-center' style={{ fontSize: '16px', fontWeight: '400', lineHeight: '20px' }}>
                                                    {address?.address},{address?.landmark},{address?.city},{address?.state}-{address?.pincode}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-end mb-2'>
                                            <button
                                                style={{ border: 'none', background: 'white', color: '#E4510B', marginTop: '-10px' }}
                                                onClick={() => openEditModal(address)}
                                            >
                                                <BiEditAlt size={25} style={{ color: '#E4510B' }} />
                                            </button>
                                            <button
                                                style={{ border: 'none', background: 'white', color: '#E4510B', marginTop: '-10px' }}
                                                onClick={() => handleAddressDelete(address?._id)}
                                            >
                                                <RiDeleteBin6Line size={25} style={{ color: '#E4510B' }} />
                                            </button>
                                        </div>
                                    </div>
                                ))}

                                {getProfile1?.shippingAddress?.length > 4 && (
                                    <div className='d-flex align-items-center mt-3 ms-2'>
                                        <p style={{ color: '#E4510B', cursor: 'pointer', fontSize: '20px', fontWeight: '500' }} onClick={() => setShowAll(!showAll)}>
                                            <FaChevronDown style={{ color: '#E4510B' }} className='me-5' />
                                            {showAll ? 'Hide addresses' : 'View all addresses'}
                                        </p>
                                    </div>
                                )}

                            </div>
                            <div className='mt-4 d-flex ' style={{ border: '1px solid #F0F0F0', borderRadius: '15px', paddingLeft: '3%', height: '60px' }}>
                                <div style={{ cursor: 'pointer' }} className='mt-3' onClick={openAddAddressModal}>
                                    <p style={{ fontSize: '20px', color: '#E4510B', fontWeight: '500', lineHeight: '20px' }}><FaPlus style={{ color: '#E4510B' }} className='me-4' /> Add a new address</p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className='mt-5' style={{ paddingRight: '2%' }}>
                                <div style={{ paddingLeft: '3%', paddingRight: '3%', paddingTop: '3%', border: '1px solid #F0F0F0', borderRadius: '15px', boxShadow: 'rgba(0, 0, 0, 0.05)' }}>
                                    <div style={{ borderBottom: '1px solid #F0F0F0', paddingBottom: '10px' }}>
                                        <p style={{ color: '#2B2B2D', fontSize: '20px', lineHeight: '24px', fontWeight: '700' }}>Add Delivery Address</p>
                                    </div>
                                    <div className='mt-3 mb-3'>
                                        <Form onSubmit={handleSubmit(submitForm)}>
                                            <div className='ps-3 pe-3'>
                                                <div className='d-flex justify-content-between'>
                                                    <div className='mb-2 w-50'>
                                                        <Label>First Name <span style={{ color: 'red' }}>*</span> </Label>
                                                        <Controller
                                                            name='firstName'
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <Input
                                                                    type="text"
                                                                    {...field}
                                                                    invalid={errors.firstName && true}
                                                                    placeholder="Enter Your First Name"
                                                                />
                                                            )}
                                                        />
                                                        {errors.firstName && <FormFeedback>{errors.firstName.message}</FormFeedback>}
                                                    </div>
                                                    <div className='mb-2 w-50 ms-3'>
                                                        <Label>Last Name <span style={{ color: 'red' }}>*</span> </Label>
                                                        <Controller
                                                            name='lastName'
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <Input
                                                                    type="text"
                                                                    {...field}
                                                                    invalid={errors.lastName && true}
                                                                    placeholder="Enter Your Last Name"
                                                                />
                                                            )}
                                                        />
                                                        {errors.lastName && <FormFeedback>{errors.lastName.message}</FormFeedback>}
                                                    </div>
                                                </div>
                                                <div className='mb-2'>
                                                    <Label>State  <span style={{ color: 'red' }}>*</span></Label>
                                                    <Controller
                                                        name='state'
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) => (
                                                            <Input
                                                                type="text"
                                                                {...field}
                                                                invalid={errors.state && true}
                                                                placeholder="Enter Your State"
                                                            />
                                                        )}
                                                    />
                                                    {errors.State && <FormFeedback>{errors.State.message}</FormFeedback>}
                                                </div>
                                                <div className='mb-2'>
                                                    <Label>City/Town  <span style={{ color: 'red' }}>*</span></Label>
                                                    <Controller
                                                        name='city'
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) => (
                                                            <Input
                                                                type="text"
                                                                {...field}
                                                                invalid={errors.city && true}
                                                                placeholder="Enter Your City"
                                                            />
                                                        )}
                                                    />
                                                    {errors.city && <FormFeedback>{errors.city.message}</FormFeedback>}
                                                </div>
                                                <div className='mb-2'>
                                                    <Label>Landmark  <span style={{ color: 'red' }}>*</span></Label>
                                                    <Controller
                                                        name='landmark'
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) => (
                                                            <Input
                                                                type="text"
                                                                {...field}
                                                                invalid={errors.landmark && true}
                                                                placeholder="Enter Landmark"
                                                            />
                                                        )}
                                                    />
                                                    {errors.landmark && <FormFeedback>{errors.landmark.message}</FormFeedback>}
                                                </div>
                                                <div className='mb-2'>
                                                    <Label>Address  <span style={{ color: 'red' }}>*</span></Label>
                                                    <Controller
                                                        name='address'
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) => (
                                                            <Input
                                                                type="text"
                                                                {...field}
                                                                invalid={errors.address && true}
                                                                placeholder="Enter Your Address"
                                                            />
                                                        )}
                                                    />
                                                    {errors.address && <FormFeedback>{errors.address.message}</FormFeedback>}
                                                </div>
                                                <div className='mb-2'>
                                                    <Label>Pincode <span style={{ color: 'red' }}>*</span> </Label>
                                                    <Controller
                                                        name='pincode'
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) => (
                                                            <Input
                                                                type="text"
                                                                {...field}
                                                                invalid={errors.pincode && true}
                                                                placeholder="Enter Pincode"
                                                            />
                                                        )}
                                                    />
                                                    {errors.pincode && <FormFeedback>{errors.pincode.message}</FormFeedback>}
                                                </div>
                                                <div className='mb-2'>
                                                    <Label>Phone <span style={{ color: 'red' }}>*</span> </Label>
                                                    <Controller
                                                        name='mobile'
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) => (
                                                            <Input
                                                                type="text"
                                                                {...field}
                                                                invalid={errors.mobile && true}
                                                                placeholder="Enter Mobile Number"
                                                            />
                                                        )}
                                                    />
                                                    {errors.mobile && <FormFeedback>{errors.mobile.message}</FormFeedback>}
                                                </div>
                                                <div className='mb-2'>
                                                    <Label>Alternate Phone <span style={{ color: 'red' }}>*</span> </Label>
                                                    <Controller
                                                        name='alternateMobile'
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) => (
                                                            <Input
                                                                type="text"
                                                                {...field}
                                                                invalid={errors.alternateMobile && true}
                                                                placeholder="Enter Alternate Mobile Number"
                                                            />
                                                        )}
                                                    />
                                                    {errors.alternateMobile && <FormFeedback>{errors.alternateMobile.message}</FormFeedback>}
                                                </div>
                                                <div className='mb-2 mt-3 d-flex'>
                                                    <Controller
                                                        name='defaultAddress'
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) => (
                                                            <Input
                                                                type="checkbox"
                                                                {...field}
                                                                invalid={errors.defaultAddress && true}
                                                                checked={field.value}
                                                            />
                                                        )}
                                                    />
                                                    <Label className='mt-2 ms-3'>Set as default shipping address </Label>
                                                    {errors.defaultAddress && <FormFeedback>{errors.defaultAddress.message}</FormFeedback>}
                                                </div>
                                            </div>
                                            <div style={{ justifyContent: 'center' }} className="modal-footer d-flex">
                                                <Button className='ms-3 mt-2 me-2' type='submit' style={{ backgroundColor: "#E4510B", border: 'none', width: '100%' }} >
                                                    {selectedAddress ? 'Update Address' : 'Use this address'}
                                                </Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </>

                    )}
                </Col>

            </div>
            {/* Name Modal */}
            <Modal isOpen={modalOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Edit Your Profile Name</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit(submitForm)}>
                        <img src={name} className='mobile-modal' style={{ height: '200px', width: '200px', display: 'block', margin: 'auto' }} />
                        <Label>Name</Label>
                        <Controller
                            name='name'
                            id='name'
                            control={control}
                            defaultValue={data?.name}
                            render={({ field }) => (<Input type="text" {...field} invalid={errors.name && true} placeholder="Enter Your Name" />)} />
                        {errors.name && <FormFeedback>{errors.name.message}</FormFeedback>}
                        <button type='submit' className='mt-3' style={{ width: '100%', backgroundColor: '#e4510b', border: 'none', padding: '8px', borderRadius: '7px', color: 'white' }}>Submit</button>
                    </Form>
                </ModalBody>
            </Modal>

            {/* Mobile Modal */}
            <Modal isOpen={mobileModalOpen} toggle={mobileModal}>
                <ModalHeader toggle={mobileModal}>Edit Your Mobile Number</ModalHeader>
                <ModalBody>
                    <img src={mobile} className='mobile-modal' style={{ height: '200px', width: '200px', display: 'block', margin: 'auto' }} />
                    <h5 className='text-center mt-2'>Verify Your Mobile Number <br /> To Proceed</h5>
                    <p style={{ fontSize: '14px' }}>We require this verification in order to confirm that you are a genuine user.</p>
                    <Label>Mobile</Label>
                    <Controller
                        name='mobile'
                        id='mobile'
                        control={control}
                        defaultValue={data?.mobile}
                        render={({ field }) => (<Input type="number" {...field} invalid={errors.mobile && true} placeholder="Enter Your Mobile" />)} />
                    {errors.mobile && <FormFeedback>{errors.mobile.message}</FormFeedback>}
                    <button type='submit' className='mt-3' style={{ width: '100%', backgroundColor: '#e4510b', border: 'none', padding: '8px', borderRadius: '7px', color: 'white' }}>Generate OTP</button>
                </ModalBody>
            </Modal>
            {/* Email Modal */}
            <Modal isOpen={emailModalOpen} toggle={emailModal}>
                <ModalHeader toggle={emailModal}>Edit Your Email</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit(submitEmailForm)}>
                        <img src={email} className='mobile-modal' style={{ height: '250px', width: '200px', display: 'block', margin: 'auto' }} />
                        <h5 className='text-center mt-2'>Verify Your Email<br /> To Proceed</h5>
                        <Label>Email</Label>
                        <Controller
                            name='email'
                            id='email'
                            control={control}
                            defaultValue={data?.email}
                            render={({ field }) => (<Input type="email" {...field} invalid={errors.email && true} placeholder="Enter Your Email" />)} />
                        {errors.email && <FormFeedback>{errors.email.message}</FormFeedback>}
                        <button type='submit' className='mt-3' style={{ width: '100%', backgroundColor: '#e4510b', border: 'none', padding: '8px', borderRadius: '7px', color: 'white' }}>Submit</button>
                    </Form>
                </ModalBody>
            </Modal>

            {/* Add and Edit modal for Address*/}
            <Modal isOpen={addAddressModal} toggle={closeAddAddressModal} style={{ borderRadius: '15px' }} className="modal-dialog-centered modal-xs">
                <div className="d-flex align-items-center justify-content-between px-1" style={{ backgroundColor: 'white', borderBottom: '1px solid #EEEEEE', borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }}>
                    <h4 className="modal-title" style={{ marginTop: '23px', marginBottom: '23px', paddingLeft: '10px', fontFamily: 'roboto', color: 'black' }}>
                        {selectedAddress ? 'Edit Delivery Address' : 'Add New Delivery Address'}
                    </h4>
                    <X style={{ color: 'black', cursor: 'pointer' }} className="addmodal-close fw-normal mt-25 me-2" size={25} onClick={closeAddAddressModal} />
                </div>
                <ModalBody>
                    <Form onSubmit={handleSubmit(submitForm)}>
                        <div className='ps-3 pe-3'>
                            <div className='d-flex justify-content-between'>
                                <div className='mb-2'>
                                    <Label>First Name <span style={{ color: 'red' }}>*</span> </Label>
                                    <Controller
                                        name='firstName'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input
                                                type="text"
                                                {...field}
                                                invalid={errors.firstName && true}
                                                placeholder="Enter Your First Name"
                                            />
                                        )}
                                    />
                                    {errors.firstName && <FormFeedback>{errors.firstName.message}</FormFeedback>}
                                </div>
                                <div className='mb-2'>
                                    <Label>Last Name <span style={{ color: 'red' }}>*</span> </Label>
                                    <Controller
                                        name='lastName'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input
                                                type="text"
                                                {...field}
                                                invalid={errors.lastName && true}
                                                placeholder="Enter Your Last Name"
                                            />
                                        )}
                                    />
                                    {errors.lastName && <FormFeedback>{errors.lastName.message}</FormFeedback>}
                                </div>
                            </div>
                            <div className='mb-2'>
                                <Label>State  <span style={{ color: 'red' }}>*</span></Label>
                                <Controller
                                    name='state'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Input
                                            type="text"
                                            {...field}
                                            invalid={errors.state && true}
                                            placeholder="Enter Your State"
                                        />
                                    )}
                                />
                                {errors.State && <FormFeedback>{errors.State.message}</FormFeedback>}
                            </div>
                            <div className='mb-2'>
                                <Label>City/Town  <span style={{ color: 'red' }}>*</span></Label>
                                <Controller
                                    name='city'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Input
                                            type="text"
                                            {...field}
                                            invalid={errors.city && true}
                                            placeholder="Enter Your City"
                                        />
                                    )}
                                />
                                {errors.city && <FormFeedback>{errors.city.message}</FormFeedback>}
                            </div>
                            <div className='mb-2'>
                                <Label>Landmark  <span style={{ color: 'red' }}>*</span></Label>
                                <Controller
                                    name='landmark'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Input
                                            type="text"
                                            {...field}
                                            invalid={errors.landmark && true}
                                            placeholder="Enter Landmark"
                                        />
                                    )}
                                />
                                {errors.landmark && <FormFeedback>{errors.landmark.message}</FormFeedback>}
                            </div>
                            <div className='mb-2'>
                                <Label>Address  <span style={{ color: 'red' }}>*</span></Label>
                                <Controller
                                    name='address'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Input
                                            type="text"
                                            {...field}
                                            invalid={errors.address && true}
                                            placeholder="Enter Your Address"
                                        />
                                    )}
                                />
                                {errors.address && <FormFeedback>{errors.address.message}</FormFeedback>}
                            </div>
                            <div className='mb-2'>
                                <Label>Pincode <span style={{ color: 'red' }}>*</span> </Label>
                                <Controller
                                    name='pincode'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Input
                                            type="text"
                                            {...field}
                                            invalid={errors.pincode && true}
                                            placeholder="Enter Pincode"
                                        />
                                    )}
                                />
                                {errors.pincode && <FormFeedback>{errors.pincode.message}</FormFeedback>}
                            </div>
                            <div className='mb-2'>
                                <Label>Phone <span style={{ color: 'red' }}>*</span> </Label>
                                <Controller
                                    name='mobile'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Input
                                            type="text"
                                            {...field}
                                            invalid={errors.mobile && true}
                                            placeholder="Enter Mobile Number"
                                        />
                                    )}
                                />
                                {errors.mobile && <FormFeedback>{errors.mobile.message}</FormFeedback>}
                            </div>
                            <div className='mb-2'>
                                <Label>Alternate Phone <span style={{ color: 'red' }}>*</span> </Label>
                                <Controller
                                    name='alternateMobile'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Input
                                            type="text"
                                            {...field}
                                            invalid={errors.alternateMobile && true}
                                            placeholder="Enter Alternate Mobile Number"
                                        />
                                    )}
                                />
                                {errors.alternateMobile && <FormFeedback>{errors.alternateMobile.message}</FormFeedback>}
                            </div>
                            <div className='mb-2 mt-3 d-flex'>
                                <Controller
                                    name='defaultAddress'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Input
                                            type="checkbox"
                                            {...field}
                                            invalid={errors.defaultAddress && true}
                                            checked={field.value}
                                        />
                                    )}
                                />
                                <Label className='mt-2 ms-3'>Set as default shipping address </Label>
                                {errors.defaultAddress && <FormFeedback>{errors.defaultAddress.message}</FormFeedback>}
                            </div>
                        </div>
                        <div style={{ justifyContent: 'center' }} className="modal-footer d-flex">
                            <Button style={{ color: '#E4510B', background: 'white', border: '1px solid #E4510B', width: '40%' }} onClick={closeAddAddressModal} >
                                Cancel
                            </Button>
                            <Button className='ms-3' type='submit' style={{ backgroundColor: "#E4510B", border: 'none', width: '40%' }} >
                                Use this address
                            </Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>

            <FooterSection />
        </div>
    )
}

export default MyProfile
import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar'
import FooterSection from '../../footer'
import { Col, Row, Spinner } from 'reactstrap'
import './index.css'
import { Check } from 'react-feather'
import { cancelOrder, cancelReason, customerProducts, trackingDetails } from '../../../ApiConfigs/ApiConfig'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import MyOrderCard, { getDeliveryStatus } from '../MyOrderCard'
import CancelModal from './CancelModal'
import { formatDate } from '..'
import ChangeModel from '../changeAddress'

function ViewOrderDetail() {
    const [dataa, setData] = useState([]);
    const [trackingData, setTrackingData] = useState();
    const [cancelData, setCancelData] = useState();
    const [loading, setLoading] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [openChangeAddressModal, setOpenChangeAddressModal] = useState(false);
    const { id } = useParams()

    const additionalData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${customerProducts}/${id}`)
            setData(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        } finally {
            setLoading(false);
        }
    }
    const trackingDataGet = async () => {
        try {
            const response = await axios.get(`${trackingDetails}/${id}`)
            setTrackingData(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    }
    const cancelDataGet = async () => {
        try {
            const response = await axios.get(`${cancelReason}`)
            setCancelData(response?.data?.result)
        } catch (error) {
            // toast.error(error?.response?.data?.msg);
            console.log(error?.response?.data?.msg);
        }
    }

    useEffect(() => {
        additionalData()
        trackingDataGet()
        cancelDataGet()
    }, [])
    useEffect(() => {
        additionalData()
        trackingDataGet()
        cancelDataGet()
    }, [id])

    const addressDetails = dataa?.shippingAddress

    const refetchFunc = () => {
        additionalData()
        trackingDataGet()
        cancelDataGet()
    }

    return (
        <>
            <Navbar />
            {loading ? (
                <div
                    style={{
                        height: "80vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Spinner color="primary">Loading...</Spinner>
                </div>
            ) : (
                <div className='pppaa' style={{ paddingLeft: '5%', paddingRight: '5%', paddingBottom: '10%', background: '#FAFAFA' }}>
                    <div style={{ width: '100%' }} className='d-flex align-items-center pt-5 mb-3'>
                        <h1 className='product-overall-title' style={{ font: 'Manrope', }}>My Orders {dataa?.otherProducts?.length > 0 ? (<span>({dataa?.otherProducts?.length + 1})</span>) : ''}</h1>
                        <hr className='ms-3 call2' style={{ height: '2px', width: '100%', background: 'black' }} />
                    </div>
                    <Row>
                        <Col sm={12} lg={8}>
                            <div className='view-order-card p-3'>
                                <div className='d-flex justify-content-between'>
                                    <div className='d-flex '>
                                        {dataa?.customizeImages?.[0] ? (
                                            <img
                                                alt={`product image`}
                                                src={dataa?.customizeImages?.[0]}
                                                className='my-order-img'
                                            />
                                        ) : (
                                            <a href={dataa?.file?.[0]} target='_blank'>
                                                <img
                                                    alt={`product image`}
                                                    src={dataa?.file?.[0].endsWith('.pdf') ? 'https://aimmgr.s3.ap-south-1.amazonaws.com/mgr/public/socialpost/file-blvvhhfusm11y4jw4.png' : dataa?.file?.[0]}
                                                    className='my-order-img'
                                                />
                                            </a>
                                        )}
                                        <div className='ms-3'>
                                            <h1 className='h5'>{`${dataa?.product?.name}`}</h1>
                                            <p style={{ color: '#858585' }} className='p-0 m-0 mb-2'>Quantity : {`${dataa?.quantity}`}</p>
                                            <h1 className='h6'>Order placed on : {`${formatDate(dataa?.date)}`}</h1>
                                            <h1 className='h5 text-success p-0 m-0'>₹{`${parseFloat(dataa?.total).toFixed(2)}`}</h1>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column justify-content-between align-items-end'>
                                        {getDeliveryStatus(dataa?.status)}
                                        {trackingData?.[4]?.date && (
                                            <h1 className='h6 m-0 p-0'>Delivery expected by {formatDate(trackingData?.[4]?.date)}</h1>
                                        )}
                                    </div>
                                </div>
                                <div className='mt-4 mb-3' style={{ backgroundColor: 'rgb(225, 225, 225)', height: '1px', width: '100%' }}></div>
                                {dataa?.isCancelled ? (
                                    <div className='w-100'>
                                        <div>
                                            <div className='d-flex' style={{ gap: '540px' }}>
                                                <h1 className='h6'>Order Confirmed</h1>
                                                <h1 className='h6'>Cancelled</h1>
                                            </div>
                                            <div className='d-flex ms-5 mt-2'>
                                                <div>
                                                    <div className='d-flex align-items-center justify-content-end'>
                                                        <div className={`tick-red`}>
                                                            <span><Check size={26} /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='d-flex align-items-center justify-content-end'>
                                                        <div className={`bar-line-1 bar-line-clr-3`}></div>
                                                        <div className={`tick-red`}>
                                                            <span><Check size={26} /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex' style={{ gap: '540px' }}>
                                                <h1 style={{ color: '#858585' }} className='h6 mt-3'>{formatDate(dataa?.date)}</h1>
                                                <h1 style={{ color: '#858585' }} className='h6 mt-3'>{formatDate(dataa?.cancelDetails?.date)}</h1>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='d-flex align-items-end justify-content-between'>
                                        <div>
                                            <div className='d-flex' style={{ gap: '130px' }}>
                                                {trackingData?.slice(0, 4)?.map((item, i) => (
                                                    <h1 key={i} className='h6'>{item?.title}</h1>
                                                ))}
                                            </div>
                                            <div className='d-flex ms-5 mt-2'>
                                                {trackingData?.slice(0, 4)?.map((item, i) => (
                                                    <div>
                                                        <div className='d-flex align-items-center justify-content-end'>
                                                            {i !== 0 && (
                                                                <div className={`bar-line ${item?.status ? 'bar-line-clr-2' : 'bar-line-clr-1'}`}></div>
                                                            )}
                                                            <div className={` ${item?.status ? 'tick-green' : 'tick-normal'}`}>
                                                                <span><Check size={26} /></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='d-flex' style={{ gap: '100px' }}>
                                                {trackingData?.slice(0, 4)?.map((item, i) => (
                                                    <h1 key={i} style={{ color: '#858585', width: '130px' }} className='h6 mt-3'>{item?.date ? formatDate(item?.date) : ''}</h1>
                                                ))}
                                            </div>
                                        </div>
                                        {trackingData?.[1]?.status !== true && (
                                            <button
                                                className='order-cancel-btn'
                                                onClick={() => setOpenCancelModal(true)}
                                            >
                                                Cancel
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </Col>
                        <Col sm={12} lg={4}>
                            <div className='view-order-card p-3 h-100'>
                                <h1 className='h5'>Delivery Address</h1>
                                <div className='d-flex justify-content-between align-items-center mt-3'>
                                    <h1 style={{ fontSize: '16px' }} className='p-0 m-0'>{addressDetails?.firstName} {addressDetails?.lastName}</h1>
                                    {trackingData?.[1]?.status !== true && (
                                        <button
                                            className='order-cancel-btn'
                                            onClick={() => setOpenChangeAddressModal(true)}
                                        >
                                            Change
                                        </button>
                                    )}
                                </div>
                                <div className='w-75 mt-4'>
                                    <h1 style={{ fontSize: '16px' }} className=''>{addressDetails?.address}, {addressDetails?.landmark}, {addressDetails?.city} - {addressDetails?.pincode}, {addressDetails?.state}.</h1>
                                </div>

                                <h1 style={{ fontSize: '16px' }} className='mt-4'>Phone Number : {addressDetails?.mobile} </h1>
                                <h1 style={{ fontSize: '16px' }} className=''>Alternate Phone Number : {addressDetails?.alternateMobile} </h1>
                            </div>
                        </Col>
                    </Row>
                    {dataa?.otherProducts?.length > 0 && (
                        <>
                            <div style={{ width: '100%' }} className='d-flex align-items-center mt-5 mb-3'>
                                <h1 className='product-overall-title' style={{ font: 'Manrope', }}>Other items in this order</h1>
                                <hr className='ms-3 call2' style={{ height: '2px', width: '100%', background: 'black' }} />
                            </div>
                            <div>
                                {dataa?.otherProducts?.map((item, i) => (
                                    <div key={i}>
                                        <MyOrderCard data={item} />
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div >
            )}
            <FooterSection />
            <CancelModal openModal={openCancelModal} id={dataa?._id} data={cancelData} handleClose={() => setOpenCancelModal(!openCancelModal)} refetch={refetchFunc} />
            <ChangeModel openAddressModal={openChangeAddressModal} handleAddressClose={() => setOpenChangeAddressModal(!openChangeAddressModal)} />
        </>
    )
}

export default ViewOrderDetail

import React, { useEffect, useState } from 'react'
import { wishList } from '../../ApiConfigs/ApiConfig';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Button, Col, Modal, ModalBody, Spinner } from 'reactstrap';
import { ArrowRight } from 'react-feather';
import emptyCart from '../../assets/images/banner/carttt (1).png'
import "./index.css"
import categoryLeft from "../../assets/images/banner/Category-left.png";
import categoryRight from "../../assets/images/banner/Category-right.png";
import star from "../../assets/images/logo/Star 2.png"
import star5 from "../../assets/images/logo/Star 5.png"
import { FaAngleRight } from "react-icons/fa6";
import { IoTrashOutline } from "react-icons/io5";


function Wishlist({ wishlistGet }) {

    const [loading, setLoading] = useState(false);
    const [wishlistData, setWishlistData] = useState([]);
    // const queryClient = useQueryClient();



    const [openAccordion, setOpenAccordion] = useState(null);

    const numberOfData = wishlistData?.length;

    const additionalData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${wishList}`)
            setWishlistData(response?.data?.result)

        } catch (error) {

        } finally {
            setLoading(false);
        }
    }


    const removeItemFromWishlist = async (productId) => {
        try {
            setLoading(true);
            const response = await axios.post(`${wishList}`, { product: productId });
            additionalData()
            wishlistGet()
            // setWishlistData((prevData) => prevData.filter((item) => item.product._id !== productId));
            toast.success(response?.data?.msg);
        } catch (error) {
            console.error('Error removing item from wishlist', error);
            toast.error('Error removing item from wishlist');
        } finally {
            setLoading(false);
        }
    };



    useEffect(() => {
        if (localStorage.getItem('token')) {
            additionalData()
        }

    }, [localStorage.getItem('token')])

    const navigate = useNavigate()

    const navToProductView = (id) => {
        navigate(`/products/viewProduct/${id}`)
    }

    const truncateText = (text, wordLimit) => {
        const words = text?.split(' ');
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
    };

    return (
        <>

            {/* <Navbar /> */}

            <div className='about1'>
                <Col lg={12} md={12} sm={12}>
                    <div className='d-flex justify-content-center align-items-center'>
                        <div className='d-flex categ11 mt-5'>
                            <div>
                                <img className='img1' src={categoryLeft} alt="" />
                            </div>
                            <div className=' ms-2 me-2 mt-2 d-flex justify-content-center align-items-center'>
                                <h3 style={{ fontWeight: '400', fontSize: '40px', font: 'Manrope' }}>My Wishlist</h3>
                            </div>
                            <div>
                                <img className='img1' src={categoryRight} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='aout1-pa mt-2'>
                        <p className='text-center'>Welcome to your Wishlist! Here, you can save all your favorite custom products in one convenient place. Whether you’re eyeing a unique piece of apparel, a <br className='br' /> trendy accessory, or a special gift, your Wishlist helps you keep track of the items you love.</p>
                    </div>
                </Col>
            </div>
            <div className='pt-0 mob-wish' style={{ paddingLeft: '5%', paddingRight: '5%', paddingBottom: '12%', background: '#FAFAFA' }}>
                {
                    loading ? (
                        <div style={{ height: '310px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Spinner
                                color="primary"
                                size=""
                            >
                                Loading...
                            </Spinner>
                        </div>
                    ) :
                        (<>
                            <div style={{ background: '#FAFAFA' }} className='pt-5 tab1'>
                                {numberOfData ? (
                                    <>
                                        <div className=''>
                                            <div style={{ width: '100%' }} className='d-flex align-items-center'>
                                                <h1 className='product-overall-title'>All Wishlist (<span>{numberOfData}</span> item)</h1>
                                                <hr className='ms-3 call2' style={{ height: '2px', width: '100%', background: 'black' }} />
                                            </div>
                                            <div className='table-responsive mt-4'>
                                                <table style={{ width: '100%' }}>
                                                    <thead>
                                                        <tr style={{ background: '#EEEEEE', height: '50px', borderRadius: '6px' }}>
                                                            <td><p className='ms-3' style={{ fontSize: '20px', fontWeight: '700', lineHeight: '35px' }}>Product</p></td>
                                                            <td><p className='ms-3' style={{ fontSize: '20px', fontWeight: '700', lineHeight: '35px' }}>Product Description</p></td>
                                                            <td><p className='ms-3' style={{ fontSize: '20px', fontWeight: '700', lineHeight: '35px' }}>Product Price</p></td>
                                                            <td><p className='ms-3' style={{ fontSize: '20px', fontWeight: '700', lineHeight: '35px' }}>Action</p></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {wishlistData?.map((eachItem, i) => (
                                                            <tr style={{ border: '1px solid #EEEEEE', background: 'white', borderRadius: '6px', boxShadow: 'rgba(0, 0, 0, 0.06)' }}>
                                                                <td>
                                                                    <div className='ms-3 mt-3 mb-3' style={{ borderRight: '1px solid #D4D4D4', display: 'flex', alignItems: 'center', width: '300px' }}>
                                                                        <div>
                                                                            <img style={{ width: '80px', height: '80px', borderRadius: '5px' }} src={eachItem?.product?.image} alt="" />
                                                                        </div>
                                                                        <div className='d-block ms-3 me-3'>
                                                                            <h5>{eachItem?.product?.name}</h5>
                                                                            <h6>{eachItem?.product?.productCategory?.name}</h6>
                                                                            <div style={{ marginTop: '-3px' }}>
                                                                                <img src={star} alt="star1" />
                                                                                <img className='ms-1' src={star} alt="star2" />
                                                                                <img className='ms-1' src={star} alt="star3" />
                                                                                <img className='ms-1' src={star} alt="star4" />
                                                                                <img className='ms-1' src={star5} alt="star5" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div style={{ borderRight: '1px solid #D4D4D4', width: '550px' }}>
                                                                        <p className='wish-para mt-3 ms-3 me-3' style={{
                                                                            fontSize: '16px', fontFamily: 'Lato', fontWeight: '400', color: '#858585'
                                                                        }}>{truncateText(eachItem?.product?.fullDescription, 30)}</p>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div style={{ borderRight: '1px solid #D4D4D4', width: '150px' }} className="d-flex flex-column ">
                                                                        <div className='d-flex align-items-center ms-3 me-3'>
                                                                            <h2
                                                                                style={{
                                                                                    fontSize: `${eachItem?.product?.mimimumAmount &&
                                                                                        eachItem?.product?.mimimumAmount.toString().length > 1
                                                                                        ? eachItem.mimimumAmount.toString().length > 4
                                                                                            ? "18px"
                                                                                            : "24px"
                                                                                        : "24px"
                                                                                        }`,
                                                                                    color: '#37A003',
                                                                                }}
                                                                            >
                                                                                {eachItem?.offer === true ? ((eachItem?.offerAmount * 10 || 0).toLocaleString('en-IN', {
                                                                                    maximumFractionDigits: 0,
                                                                                    style: 'currency',
                                                                                    currency: 'INR',
                                                                                })) : ((eachItem?.mimimumAmount * 10 || 0).toLocaleString('en-IN', {
                                                                                    maximumFractionDigits: 0,
                                                                                    style: 'currency',
                                                                                    currency: 'INR',
                                                                                }))}
                                                                            </h2>
                                                                            {eachItem?.offer === true ? (
                                                                                <span style={{ fontSize: "16px", display: 'flex', marginTop: '2px', marginLeft: '10px', textDecoration: 'line-through' }} className='old-price'>
                                                                                    {(eachItem?.mimimumAmount * 10).toLocaleString('en-IN', {
                                                                                        maximumFractionDigits: 0,
                                                                                        style: 'currency',
                                                                                        currency: 'INR',
                                                                                    })}
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                        <span className='ms-3' style={{ color: '#858585', fontSize: "16px", marginTop: '5px' }}>
                                                                            For 100 pieces
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div style={{ width: '150px' }} className='d-flex ms-3'>
                                                                        <button className='explore1' style={{ border: 'none', padding: '5px 10px', background: '#FF6600', color: 'white', borderRadius: '5px' }} onClick={() => navToProductView(eachItem?.product?._id)}>
                                                                            View<FaAngleRight className='d-flex justify-content-center align-items-center mt-1 ms-1' size={15} />
                                                                        </button>
                                                                        <button className='ms-2 d-flex justify-content-center align-items-center' style={{ border: 'none', background: '#E425251A', opacity: '0.9', borderRadius: '5px', padding: '5px 10px' }} onClick={() => removeItemFromWishlist(eachItem?.product?._id)}>
                                                                            <IoTrashOutline style={{ color: '#E42525' }} size={20} />
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>



                                            <Modal className="modal-dialog-centered modal-xs">
                                                {/* <ModalHeader style={{ paddingLeft: '20px', fontFamily: 'italic', textAlign: 'center' }} toggle={closeDeleteModal}>Confirm Delete</ModalHeader> */}
                                                <ModalBody style={{ fontSize: '20px', textAlign: 'center', paddingTop: '30px', fontWeight: '400' }}>Are you sure you want to delete this Item?</ModalBody>
                                                <div style={{ justifyContent: 'center' }} className="modal-footer">
                                                    <Button style={{ backgroundColor: "#E4510B", border: 'none' }} >
                                                        Confirm
                                                    </Button>
                                                    <Button color="secondary"  >
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </Modal>
                                        </div>
                                        <p
                                            className="view-all-fast mt-4"
                                            onClick={() => navigate("/products")}
                                        >
                                            Continue Shopping <ArrowRight className=" ms-2 mt-2" />
                                        </p>
                                    </>
                                ) : (
                                    <>

                                        <div className='d-flex justify-content-center'>

                                            <img src={emptyCart} className="img-fuild empty-card-img" alt="empty-cart" />
                                        </div>
                                        <h4 className='text-center m-0 p-0 cart-empty-para'>This is embarrassing!<br />Your Wishlist is empty at the moment.</h4>
                                        <div className='d-flex justify-content-center mt-3'>
                                            <Link to='/products'>
                                                <button className='success-modal-btn'>Continue Shopping <ArrowRight size={17} /></button>
                                                {/* <p className='text-center text-danger p-0 m-0' style={{ textDecoration: 'underline' }}>countinue Shopping</p> */}
                                            </Link>
                                        </div>
                                    </>
                                )}
                            </div>
                        </>)

                }

            </div >

            {/* <FooterSection /> */}

        </>
    )
}

export default Wishlist

// CommonLayout.js
import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom"; // Import useLocation hook
import { domainUrl } from "../../ApiConfigs/ApiConfig";

const CommonLayout = () => {
  const location = useLocation();

  // Construct the canonical URL based on current pathname
  const canonicalUrl = `${domainUrl}${location.pathname}`;
  return (
    <Helmet>
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
};

export const HtmlHeaderElements = ({ title, description, keywords }) => {
  const defaultContent =
    "High-Quality Online Printing Services in Bangalore | Business Cards, Flyers, Banners, Invitations";
  return (
    <Helmet>
      <title>{title || defaultContent}</title>
      <meta name="description" content={description || defaultContent}></meta>
      <meta name="keywords" content={keywords || defaultContent}></meta>
    </Helmet>
  );
};
export default CommonLayout;

import React, { useEffect, useState } from 'react'
import './index.css'
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { customerProducts, orderPost, rateandreview } from '../../ApiConfigs/ApiConfig';
import { Card, Col, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import Navbar from '../navbar';
import FooterSection from '../footer';
import { AlertCircle, Edit3, ExternalLink, Eye, Search } from 'react-feather';
import toast from 'react-hot-toast';
import Preview from './preview';
import categoryLeft from "../../assets/images/banner/Category-left.png";
import categoryRight from "../../assets/images/banner/Category-right.png";
import MyOrderCard from './MyOrderCard';

export const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = date.getDate();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Add 'th', 'st', 'nd', 'rd' based on the day
    const getDayWithSuffix = (day) => {
        if (day > 3 && day < 21) return day + 'th'; // Handle 11th to 20th
        switch (day % 10) {
            case 1: return day + 'st';
            case 2: return day + 'nd';
            case 3: return day + 'rd';
            default: return day + 'th';
        }
    };
    return `${getDayWithSuffix(day)} ${month}, ${year}`;
};

export function convertTo12HourFormat(dateString) {
    const date = new Date(dateString);

    // Get hours and minutes
    let hours = date.getHours();
    const minutes = date.getMinutes();

    // Determine AM or PM
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert 24-hour time to 12-hour time
    hours = hours % 12;
    hours = hours ? hours : 12; // Adjust 0 to 12 for 12 AM and 12 PM

    // Add leading zero to minutes if needed
    const minutesString = minutes < 10 ? `0${minutes}` : minutes;

    // Return formatted time
    return `${hours}:${minutesString} ${ampm}`;
}

function MyOrderIndex() {

    const [loading, setLoading] = useState(false);
    const [dataa, setData] = useState([]);
    const [selectedRating, setSelectedRating] = useState();
    const [cartData, setCartData] = useState([]);
    const [modal, setModal] = useState(false);
    const [customizeImages, setCustomizeImages] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('all');
    const [selectedStatusDate, setSelectedDate] = useState('allDates');
    const [searchTerm, setSearchTerm] = useState('');

    // Handle the input change
    const handleInputChange = (e) => {
        setSearchTerm(e.target.value); // Update state with input value
    };

    const handleCheckboxChange = (e) => {
        const { name } = e.target;
        setSelectedStatus(name);  // Only store the clicked checkbox value
    };

    const handleCheckboxChange1 = (e) => {
        const { name } = e.target;
        setSelectedDate(name);  // Only store the clicked checkbox value
    };


    const navigate = useNavigate()

    const additionalData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${customerProducts}?status=${selectedStatus}&orderBy=${selectedStatusDate}&search=${searchTerm}`)
            // const response = await axios.get(`${customerProducts}?status=${selectedStatus}&orderBy=${selectedStatusDate}`)
            setData(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        } finally {
            setLoading(false);
        }
    }

    const numberOfData = cartData?.length;


    useEffect(() => {
        additionalData()
    }, [])

    const handleStarClick = async (ratingValue, id, userId) => {
        setSelectedRating(ratingValue);

        const payload = {
            user: userId,
            order: id,
            rating: ratingValue,
            review: 'good product'
        }
        try {
            setLoading(true);
            await axios.post(rateandreview, payload);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };
    const toggle = () => setModal(!modal);

    const viewCustom = (item) => {

        if (item?.customizeImages?.length > 1) {
            setCustomizeImages(item?.customizeImages);
            toggle()
        } else {
            setCustomizeImages(item?.file);
            toggle()
        }

    };

    useEffect(() => {
        additionalData()
    }, [selectedStatus, selectedStatusDate, searchTerm])

    return (
        <>
            <Navbar />
            <div className='about1'>
                <Col lg={12} md={12} sm={12}>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <div className='d-flex categ11 mt-5'>
                                <div>
                                    <img className='img1' src={categoryLeft} alt="" />
                                </div>
                                <div className=' ms-2 me-2 mt-2 d-flex justify-content-center align-items-center'>
                                    <h3 style={{ fontWeight: '400', fontSize: '40px', font: 'Manrope', textAlign: 'center' }}>Track and Manage Your Orders</h3>
                                </div>
                                <div>
                                    <img className='img1' src={categoryRight} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className='aout1-pa mt-2 w-75'>
                            <p className='text-center'>Welcome to your Orders screen! Here, you can view the status of all your recent and past purchases with Printon. Stay updated on your order progress, access invoices, and manage returns or exchanges effortlessly.</p>
                        </div>
                    </div>
                </Col>
            </div>
            <div className='pt-5' style={{ paddingLeft: '8%', paddingRight: '8%', paddingBottom: '10%' }}>
                <div style={{ width: '100%' }} className='d-flex align-items-center'>
                    <h1 className='product-overall-title' style={{ font: 'Manrope', }}>My Orders</h1>
                    <hr className='ms-3 call2' style={{ height: '2px', width: '100%', background: 'black' }} />
                </div>
                <div className='mt-5'>
                    <Row>
                        <Col sm={12} lg={3}>
                            <div className='filtedr-con'>
                                <div className='p-3 border-bottom border-1'>
                                    <h1 className='h4 p-0 m-0'>Filters</h1>
                                </div>
                                <div className='p-3 pt-4 pb-4 border-1 border-bottom'>
                                    <h1 className='h5 p-0 m-0' style={{ font: 'Manrope', }}>ORDER STATUS</h1>
                                    <div className='mb-2 mt-3'>
                                        <Input
                                            id="checkAll"
                                            name="all"
                                            type="checkbox"
                                            checked={selectedStatus === 'all'}
                                            onChange={handleCheckboxChange}
                                        />
                                        <Label className='ms-3 mt-1  fw-medium' check for="checkAll">
                                            All
                                        </Label>
                                    </div>
                                    <div className='mb-2'>
                                        <Input
                                            id="checkInProgress"
                                            name="process"
                                            type="checkbox"
                                            checked={selectedStatus === 'process'}
                                            onChange={handleCheckboxChange}
                                        />
                                        <Label className='ms-3 mt-1 fw-medium' check for="checkInProgress">
                                            In progress
                                        </Label>
                                    </div>
                                    <div className='mb-2'>
                                        <Input
                                            id="shipped"
                                            name="shipped"
                                            type="checkbox"
                                            checked={selectedStatus === 'shipped'}
                                            onChange={handleCheckboxChange}
                                        />
                                        <Label className='ms-3 mt-1 fw-medium' check for="shipped">
                                            Shipped
                                        </Label>
                                    </div>

                                    <div className='mb-2'>
                                        <Input
                                            id="delivered"
                                            name="delivered"
                                            type="checkbox"
                                            checked={selectedStatus === 'delivered'}
                                            onChange={handleCheckboxChange}
                                        />
                                        <Label className='ms-3 mt-1  fw-medium' check for="delivered">
                                            Delivered
                                        </Label>
                                    </div>

                                    <div>
                                        <Input
                                            id="cancelled"
                                            name="cancelled"
                                            type="checkbox"
                                            checked={selectedStatus === 'cancelled'}
                                            onChange={handleCheckboxChange}
                                        />
                                        <Label className='ms-3 mt-1  fw-medium' check for="cancelled">
                                            Cancelled
                                        </Label>
                                    </div>
                                </div>
                                <div className='p-3 pb-4 pt-4 border-1 border-bottom'>
                                    <h1 className='h5 p-0 m-0' style={{ font: 'Manrope', }}>Date Ordered</h1>
                                    <div className='mb-2 mt-3'>
                                        <Input
                                            id="AllOrders"
                                            name="allDates"
                                            type="checkbox"
                                            checked={selectedStatusDate === 'allDates'}
                                            onChange={handleCheckboxChange1}
                                        />
                                        <Label className='ms-3 mt-1  fw-medium' check for="AllOrders">
                                            All
                                        </Label>
                                    </div>
                                    <div className='mb-2'>
                                        <Input
                                            id="Last24hours"
                                            name="1"
                                            type="checkbox"
                                            checked={selectedStatusDate === '1'}
                                            onChange={handleCheckboxChange1}
                                        />
                                        <Label className='ms-3 mt-1 fw-medium' check for="Last24hours">
                                            Last 24 hours
                                        </Label>
                                    </div>
                                    <div className='mb-2'>
                                        <Input
                                            id="Last3days"
                                            name="3"
                                            type="checkbox"
                                            checked={selectedStatusDate === '3'}
                                            onChange={handleCheckboxChange1}
                                        />
                                        <Label className='ms-3 mt-1  fw-medium' check for="Last3days">
                                            Last 3 days
                                        </Label>
                                    </div>
                                    <div className='mb-2'>
                                        <Input
                                            id="Last7days"
                                            name="7"
                                            type="checkbox"
                                            checked={selectedStatusDate === '7'}
                                            onChange={handleCheckboxChange1}
                                        />
                                        <Label className='ms-3 mt-1  fw-medium' check for="Last7days">
                                            Last 7 days
                                        </Label>
                                    </div>
                                    <div className='mb-2'>
                                        <Input
                                            id="Last14days"
                                            name="14"
                                            type="checkbox"
                                            checked={selectedStatusDate === '14'}
                                            onChange={handleCheckboxChange1}
                                        />
                                        <Label className='ms-3 mt-1  fw-medium' check for="Last14days">
                                            Last 14 days
                                        </Label>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} lg={9}>
                            <div className='d-flex '>
                                <input
                                    className='my-order-search'
                                    type='text'
                                    placeholder='Search...'
                                    value={searchTerm} // Controlled input
                                    onChange={handleInputChange} // Call the handler on change
                                />
                                <button className='my-order-search-btn'><Search size={26} /> Search Order</button>
                            </div>
                            {loading ? (
                                <div
                                    style={{
                                        width: '100%',
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginTop: '50px'
                                    }}
                                >
                                    <Spinner color="primary">Loading...</Spinner>
                                </div>
                            ) : (
                                <>
                                    {dataa?.length > 0 ? (
                                        <div className='mt-3'>
                                            {dataa?.map((eachItem, i) => (
                                                <div key={i}>
                                                    <MyOrderCard data={eachItem} />
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <h1 className='h5 text-center mt-5'><AlertCircle size={30} /> No Data Found!!!</h1>
                                    )}
                                </>
                            )}
                        </Col>
                    </Row>
                </div>
                <Preview modal={modal} toggle={toggle} cardImages={customizeImages} />
            </div >
            <FooterSection />
        </>
    )
}

export default MyOrderIndex